import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import "./style.css";
import CenteredRow from "../CenteredRow";
import { connect } from 'react-redux'

const Loader = ({gloading, scope, typeClass}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(gloading);
  }, [gloading]);



  return (
    loading ? (
      <CenteredRow typeClass={typeClass} >
      {styledIcon("loading", "#287d8e", scope)}
    </CenteredRow>
      
    ) : (
      <CenteredRow typeClass={typeClass}>
        
      </CenteredRow>
    )
  );
};

const styledIcon = ( type, color, scope ) => {
  return (
    <span className={scope !== "login" ? "icon-animated" : ""}>
      <Icon
        type={type}
        style={{ color: color, fontSize: iconSize(scope) }}
        
      />
    </span>
  );
};

const iconSize = (scope) => {
  let size;
  switch (scope) {
    case "navbar":
      size = 30;
      break;
    case "action":
      size = 20;
      break;
    case "loader":
      size = 50;
      break;
    default:
      size = 16;
      break;
  };
  return size;
};

const mapStateToProps = state => {
  return {
    gloading: state.user.loading,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loader);