import React, { useEffect, useState } from 'react'
import { Row, Col, Select, Statistic, Card, message, Tag } from 'antd'
import { sensorDataTypes } from '../../../utils/enums'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import { loadSensorData } from "../../../api"

import localStorageUtil from "../../../utils/localStorageUtil";
import { setGroup, setSensorData, setCurrentSensor, setLoading, setEmptyChartData } from '../../../actions';

import "./style.css";

const { Option } = Select

const dataTypes = Object.keys(sensorDataTypes).map(k => sensorDataTypes[k])

const Data = ({ group, sensortype, sensordata, _setGroup, _setSensorData, _setCurrentSensor, _setGLoading, _setEmptyChartData }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState(useHistory());
  const [selectedType, setSelectedType] = useState(sensortype);
  const [sensorData, setSensorData] = useState([]);
  const [filteredIds, setFilteredIds] = useState([]);

  const typeChanged = async (type) => {

    setLoading(true);
    _setGLoading(true);
    
    const datavalue = await loadSensorData(group.uuid, type);
    if (window.canceldata) {
      return;
    }
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _setSensorData(datavalue.data);
    }
    setSelectedType(type);
    await _setCurrentSensor(type);
    setLoading(false);
    _setGLoading(false);
  }


  const filterDevices = ids => setFilteredIds(ids)


  const canFilterDevices = filteredIds.length > 0

  const controllers = canFilterDevices
    ? sensorData.filter(c => c.aws_id && filteredIds.includes(c.aws_id))
    : sensorData.filter(c => c.aws_id)


  useEffect(() => {
    if (group.uuid) {
      setSensorData(sensordata)
      /*if (sensorData.length > 0) {
      } else {
        typeChanged(selectedType)
      }*/
    }
  }, [sensordata])

  
  useEffect(() => {
    window.canceldata = false;
    if (group.uuid) {
      typeChanged(sensortype)
    }

    return () => {
      window.canceldata = true;
    };
  }, [group.uuid])
  

  const logout = async () => {
    await _setGroup({})
    await _setEmptyChartData()
    localStorageUtil.clearAppStorage();
    history.push("/login")
  }


  const title = () => {
    switch (selectedType) {
      case sensorDataTypes.TEMPERATURE:
        return 'Temperature'
      case sensorDataTypes.HUMIDITY:
        return 'Humidity'
      case sensorDataTypes.PRESSURE:
        return 'Pressure'
      case sensorDataTypes.LIGHT:
        return 'Lux'
      case sensorDataTypes.IR:
        return 'Irradiance'
      case sensorDataTypes.CO2:
        return 'CO2'
      case sensorDataTypes.VOC:
        return 'VOC'
      default:
        return ''
    }
  }

  const getTitle = (thesensortype) => {
    switch (thesensortype) {
      case sensorDataTypes.TEMPERATURE:
        return 'Temperature'
      case sensorDataTypes.HUMIDITY:
        return 'Humidity'
      case sensorDataTypes.PRESSURE:
        return 'Pressure'
      case sensorDataTypes.LIGHT:
        return 'Lux'
      case sensorDataTypes.IR:
        return 'Irradiance'
      case sensorDataTypes.CO2:
        return 'CO2'
      case sensorDataTypes.VOC:
        return 'VOC'
      default:
        return ''
    }
  }

  const symbol = () => {
    switch (selectedType) {
      case sensorDataTypes.TEMPERATURE:
        return (
          <span>
            <sup>o</sup>C
          </span>
        )
      case sensorDataTypes.HUMIDITY:
        return <span>%</span>
      case sensorDataTypes.PRESSURE:
        return <span>mbar</span>
        case sensorDataTypes.IR:
        return <span></span>
      case sensorDataTypes.LIGHT:
        return <span>lux</span>
      case sensorDataTypes.CO2:
        return <span>ppm</span>
      case sensorDataTypes.VOC:
        return <span>ppb</span>
      default:
        return ''
    }
  }

  const renderDeviceFilter = () => (
    <div style={{ marginRight: '15px' }}>
      <span style={{ marginRight: '5px', fontWeight: 600 }}>Filter: </span>
      <Select
        mode="multiple"
        style={{ width: 'auto', minWidth: 200 }}
        placeholder="Filter devices"
        onChange={filterDevices}
      >
        {sensorData
          .filter(c => c.aws_id)
          .map(c => (
            <Option key={c.aws_id}>{c.aws_id}</Option>
          ))}
      </Select>
    </div>
  )

  const renderSensorSelector = () => (
    <div>
      <span style={{ marginRight: '5px', fontWeight: 600 }}>
        Select Sensor:{' '}
      </span>
      <Select
        disabled={loading}
        loading={loading}
        style={{ minWidth: 150 }}
        placeholder="Select a device"
        value={selectedType}
        onChange={typeChanged}
      >
        {dataTypes.map(key => (
          <Option key={key} value={key}>
            <span style={{ textTransform: 'capitalize' }}>{getTitle(key)}</span>
          </Option>
        ))}
      </Select>
    </div>
  )
  
  const convertLastData = (sensorName, sensor_data) => {
    if (sensor_data.sensor_reading && sensorName === 'pressure') {
        const reading = sensor_data.sensor_reading
        let tmp =
            reading.includes(',') || reading.includes('.')
                ? parseFloat(reading)
                : parseInt(reading)

        tmp = (tmp / 100).toFixed(2)

        return tmp
    }
    return sensor_data.sensor_reading || 0
  }

  const renderData = () => {
    if (!sensorData) return
    return controllers.map((item) => (
      <Col key={item.aws_id} sm={{ span: 8 }} style={{ padding: 10 }}>
        <Card className="data__card">
          <div className="content-device-tags">
          <Tag className="light_capability">{item.light_capability} Colour</Tag>
          {item.uv_light_capability && (item.uv_light_capability === "UV" || item.uv_light_capability === "uv") ?
          <Tag className="light_capability"  style={{ marginLeft: "20px"}}>{item.uv_light_capability}</Tag>:<></>}
          </div>
          <span className="device-id">{item.aws_id}</span>
          <Statistic value={(item.sensor_data ? convertLastData(selectedType, item.sensor_data) : 0)} suffix={symbol()} />
          <span className="title">{title()}</span>
        </Card>
      </Col>
    ))
  }

  return (!group.uuid ? (<div>
    <div className="big-text">No group selected</div>
  </div>) : (
      <div className="group-device-data">
        <div className="header">
          <Row>
            <Col sm={{ span: 8 }}></Col>
            <Col sm={{ span: 8 }}>
              {renderDeviceFilter()}
            </Col>
            <Col sm={{ span: 8 }}>
              {renderSensorSelector()}
            </Col>
          </Row>
        </div>
        <Row className="data">{renderData()}</Row>
      </div>
    ))
}


//export default (Data);

const mapStateToProps = state => {
  return {
    sensortype: state.user.sensortype,
    sensordata: state.user.sensordata,
    group: state.user.group
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    _setGroup: data => {
      dispatch(setGroup(data));
    },
    _setEmptyChartData: () => {
      dispatch(setEmptyChartData());
    },
    _setSensorData: data => {
      dispatch(setSensorData(data));
    },
    _setCurrentSensor: data => {
      dispatch(setCurrentSensor(data));
    },

    _setGLoading: data => {
      dispatch(setLoading(data));
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Data);
