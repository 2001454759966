import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message, Button, Card, Row, Col, Notification, Alert, Icon } from 'antd'
import { withRouter } from "react-router-dom";
//import Loader from "../../components/Loader";

//import * as actions from '../constants';
import moment from 'moment';
import './styles.css';
import { getMessageIconType, getMessageType, getNotificationType } from "../../../utils/helpers";
import { updatedToastNotificationShow } from "../../../api"

import localStorageUtil from "../../../utils/localStorageUtil";


const NotificationDetail = ({
    history,
    transport,
    notification,
    deselectNotification
}) => {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        if (notification.transport === 1) {
            if (notification.notification_receipts && notification.notification_receipts.length > 0) {
                if (notification.notification_receipts[0].is_read === false) {
                    updateToastNotification(notification.uuid, { is_read: true }, false);
                }
            } else {
                updateToastNotification(notification.uuid, { is_read: true }, false);
            }
        }

    }, [notification]);


    const logout = async () => {
        //await _setGroup({})
        //await _setEmptyChartData()
        dispatch(({
            type: "SET_GROUP",
            payload: {}
        }))
        dispatch(({
            type: "SET_CHARTDATA"
        }))
        localStorageUtil.clearAppStorage();
        history.push("/login")
    }

    const backFunction = () => {
        deselectNotification();
    }

    const getTime = (param_date) => {
        const strdate = param_date.replace("T", " ");
        //convert local
        let local = moment.utc(strdate).local().format("D MMM. YYYY H:mm");
        return local;
    }

    const updateToastNotification = async (notification_uuid, form, confimed) => {
        const datavalue = await updatedToastNotificationShow(notification_uuid, form)
        if (datavalue.error) {
            if (datavalue.autherror) {
                message.info(datavalue.message);
                logout();
            } else {
                message.error(datavalue.message);
            }
        } else {

        }
        //setLoading(false);
        if (confimed) {
            await dispatch(({
                type: "SET_NOTIFICATION_RECEIPT_CONFIRMED",
                payload: { "transport": transport, "notification_uuid": notification_uuid, "notification_receipt": datavalue.data.notification_receipt }
            }))

        } else {
            await dispatch(({
                type: "SET_NOTIFICATION_RECEIPT",
                payload: { "transport": transport, "notification_uuid": notification_uuid, "notification_receipt": datavalue.data.notification_receipt }
            }))
        }

    }

    const showDescription = (notification) => {
        return (
            <>
                {notification.transport === 2 ? <Col span={24}><span className="subject">{notification.subject}</span></Col> : <></>}
                <Col span={24}><span className="message">{notification.message}</span></Col>
                {notification.transport === 1 && <>
                    <Col span={12} className="notification-detail-info"></Col>
                    <Col span={12} className="notification-detail-info" style={{ textAlign: "right", paddingRight: "0px" }}>
                        {notification.requires_confirmation ?
                            (notification.notification_receipts && notification.notification_receipts.length > 0 && notification.notification_receipts[0].is_confirmed === true ?
                                <span>Confirmed</span>
                                :
                                <Button type="primary" size="small" onClick={() => {
                                    updateToastNotification(notification.uuid, { is_confirmed: true }, true);
                                    Notification.close(notification.uuid)
                                }}>
                                    Confirm
</Button>
                            ) : <></>}
                    </Col>
                </>}
            </>
        )
    }

    return (
        loading //isLoading
            ? <></>
            :
            <>
                <Row className="notification-detail">
                    <Row style={{ marginBottom: "10px" }}>
                        <Col span={4}><Button onClick={() => backFunction()}><Icon type="arrow-left" />Back</Button></Col>
                        <Col span={20} style={{ textAlign: "center" }}><h3>{notification.transport === 1 ? "Toast" : notification.transport === 2 ? "Mail" : notification.transport === 3 ? "SMS" : ""} Notifications</h3></Col>
                    </Row>
                    <Row >
                        <Card style={{ width: "100%" }}>
                            <Col span={12} className="notification-detail-info">Notification type: {getNotificationType(notification.notification_type)}</Col>
                            <Col span={12} className="notification-detail-info align-left">Created: <span className="date">{getTime(notification.created_date)}</span></Col>
                            <Col span={12} className="notification-detail-info">Sent by: {notification.automatic ? "SYSTEM" : notification.sender.username}</Col>
                            <Col span={12} className="notification-detail-info align-left">Send: <span className="date">{getTime(notification.send_date)}</span></Col>
                            <Col span={24} className="notification-detail-info detail">
                                {notification.message_type === 4 ?
                                    <Alert
                                        message={getMessageType(notification.message_type)}
                                        description={showDescription(notification)}
                                        type={getMessageIconType(notification.message_type)}
                                        showIcon
                                        icon={<Icon type="bell" />}
                                    /> :
                                    <Alert
                                        message={getMessageType(notification.message_type)}
                                        description={showDescription(notification)}
                                        type={getMessageIconType(notification.message_type)}
                                        showIcon
                                    />
                                }
                            </Col>
                        </Card>
                    </Row>
                </Row>
            </>
    )
}

export default withRouter(NotificationDetail)

/*
uuid = fields.String(required=True)
    created_date = fields.DateTime(required=True)
    sender_date = fields.DateTime(required=True)
    automatic = fields.Boolean(required=True)
    notification_type = fields.Integer(required=True)
    message_type = fields.Integer(required=True)
    message = fields.String(required=True)
    company = fields.Nested(SimpleGroupSchema)
    building = fields.Nested(SimpleBuildingSchema)
    group = fields.Nested(SimpleCompanySchema)
    notification_toast_receipts = fields.Nested(NotificationToastReciberSchema, many=True)
    sender = fields.Nested(UserNameSchema)

*/