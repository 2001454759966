import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Empty, Icon, Radio, Row, Col, message } from "antd";
import "./styles.css";
import NotificationList from "./NotificationList";
import NotificationDetail from "./NotificationDetail";
import CenteredRow from "../../components/CenteredRow";

import { getUserNotification } from "../../api"
import localStorageUtil from "../../utils/localStorageUtil";


const UserNotification = ({
  history,
  visible
}) => {

  const [transport, setTransport] = useState("all")
  const [currentNotification, setCurrentNotification] = useState()
  const [currentNotificationUUID, setCurrentNotificationUUID] = useState("")
  const notificationUpdateDetail = useSelector((state) => state.user.notificationUpdateDetail)

  const [receipt_send, setReciberSend] = useState("receipt")
  const [newNotification, setNewNotification] = useState(false)
  const [detailNotification, setDetailNotification] = useState(false)

  const notificationData = useSelector((state) => state.user.notificationData)
  const [loading, setLoading] = useState(false)

  const notificationSendData = useSelector((state) => state.user.notificationSendData)
  const [loadingSend, setLoadingSend] = useState(false)


  const dispatch = useDispatch()


  useEffect(() => {
    setTransport("all")
  },
    []
  )


  useEffect(() => {
    if (notificationUpdateDetail === true) {
        if (currentNotificationUUID !== "") {
            if (receipt_send === "send") {

                notificationSendData[transport].forEach(element => {
                    if (currentNotificationUUID === element.uuid) {
                        setCurrentNotification(element);
                    }
                });
            } else {
                notificationData[transport].forEach(element => {
                    if (currentNotificationUUID === element.uuid) {
                        setCurrentNotification(element);
                    }
                });
            }

        }

        //put false
        dispatch(({
            type: "SET_UPDATE_NOTIFICATION_DETAIL",
            payload: false
        }))
    }
    
},
    [notificationUpdateDetail]
)

  const logout = async () => {
    dispatch(({
      type: "SET_GROUP",
      payload: {}
    }))
    dispatch(({
      type: "SET_CHARTDATA"
    }))
    localStorageUtil.clearAppStorage();
    history.push("/login")
  }

  useEffect(() => {
    if(visible){
      obtainUserNotification()
    }else{
      setTransport("all")
      setDetailNotification(false)
      setNewNotification(false)
    }
   
    return () => {
    }
  },
    [visible, transport, receipt_send])


  const obtainUserNotification = async () => {
    setLoading(true);
    //_setGLoading(true);
    dispatch(({
      type: "SET_LOADING",
      payload: true
    }))
    const datavalue = await getUserNotification(transport)
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      dispatch(({
        type: "SET_USER_NOTIFICATION",
        payload: { "transport": transport, "notification": datavalue.data.notification }
      }))
      //await _setWarmWhiteLevel(datavalue.data);
    }
    setLoading(false);
    dispatch(({
      type: "SET_LOADING",
      payload: false
    }))
    //_setGLoading(false);
  }


  const detailNotificationFunction = (index) => {
    if (receipt_send === "receipt") {
      if (notificationData && notificationData[transport].length > index) {
        setCurrentNotification(notificationData[transport][index])
        setCurrentNotificationUUID(notificationData[transport][index].uuid)
        setDetailNotification(true)
      }
    } else if (receipt_send === "send") {

      if (notificationSendData && notificationSendData[transport].length > index) {
        setCurrentNotification(notificationSendData[transport][index])
        setCurrentNotificationUUID(notificationSendData[transport][index].uuid)
        setDetailNotification(true)
      }
    }
  }

  const detailNotificationFalse = () => {
    setDetailNotification(false)
  }

  const newNotificationFalse = () => {
    setNewNotification(false)
  }



  const selectTransortShow = e => {
    setTransport(e.target.value);
  };

  const selectReceiptSend = e => {
    setReciberSend(e.target.value);
  };

  return (
    notificationData
      ? (
        <>
          {newNotification ?

            <>
              <Row>

              </Row>

            </>
            :
            detailNotification ?

              <>
                <Row>
                  <NotificationDetail
                    transport={transport}
                    notification={currentNotification}
                    deselectNotification={detailNotificationFalse}
                  />

                </Row>
              </>
              :
              <>
                <Row style={{ marginBottom: "10px" }}>

                  <Col span={24} style={{ margin: "10px 0px" }}>
                    <Radio.Group
                      className="radio-button-user-trasport"
                      onChange={selectTransortShow}
                      value={transport}
                      optionType="button"
                      buttonStyle="solid"
                    >
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="toast">Toast</Radio.Button>
                      <Radio.Button value="mail">Mail</Radio.Button>
                      <Radio.Button value="sms">SMS</Radio.Button>

                    </Radio.Group>
                  </Col>

                </Row>

                {loading ? <Row><CenteredRow typeClass="screen" >
                  <span className={"icon-animated"}>
                    <Icon
                      type="loading"
                      style={{ color: "#287d8d", fontSize: "50px" }}
                    />
                  </span>
                </CenteredRow></Row> :
                  receipt_send && receipt_send === "receipt" && notificationData && notificationData[transport] ?
                    <NotificationList
                      transport={transport}
                      notificationData={notificationData[transport]}
                      isLoading={loading}
                      selectNotification={detailNotificationFunction}
                    />
                    : receipt_send && receipt_send === "send" && notificationSendData && notificationSendData[transport] ?
                      <NotificationList
                        transport={transport}
                        notificationData={notificationSendData[transport]}
                        isLoading={loadingSend}
                        selectNotification={detailNotificationFunction}

                      />
                      :
                      <Empty />
                }
              </>
          }

        </>
      )
      : <Empty />
  );
};

/*
<Col span={24} >
                                        <Radio.Group
                                            className="radio-button-user-receipt-sender"
                                            onChange={selectReceiptSend}
                                            value={receipt_send}
                                            optionType="button"
                                            buttonStyle="solid"
                                        >
                                            <Radio.Button value="receipt">Receipt</Radio.Button>
                                            <Radio.Button value="send">Send</Radio.Button>

                                        </Radio.Group>
                                    </Col>
*/

export default withRouter(UserNotification);