import { isEmpty } from "lodash";

const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

const parse = JSON.parse;
const stringify = JSON.stringify;

/**
 *! I will be using only local storage
 */
const localStorageUtil = {
  /**
   * CLEAR
   */
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    return null;
  },

  // Clear all local storage
  clearAppStorage() {
    if (localStorage) {
      localStorage.clear();
    }
  },

  clearToken() {
    return localStorageUtil.clear(TOKEN_KEY);
  },

  clearRefreshToken() {
    return localStorageUtil.clear(REFRESH_TOKEN_KEY);
  },

  /**
   * GETTERS
   */
  get(key) {
    if (localStorage){
      if(localStorage.getItem(key)) {
      return parse(localStorage.getItem(key)) || null;
    }}
    return null;
  },

  getToken() {
    return localStorageUtil.get(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorageUtil.get(REFRESH_TOKEN_KEY);
  },

  /**
   * SETTERS
   */
  set(value, key) {
    if (isEmpty(value)) {
      return null;
    }
    
    if (localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    return null;
  },
  setToken(value) {
    return localStorageUtil.set(value, TOKEN_KEY);
  },
  setRefreshToken(value) {
    return localStorageUtil.set(value, REFRESH_TOKEN_KEY);
  }
};

export default localStorageUtil;