import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { message } from 'antd';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';
import { Icon } from 'antd';
import moment from 'moment';
import CenteredRow from "../../../components/CenteredRow";
import "./style.css";

import { loadChartSensorDataMotion } from "../../../api";
import { setGroup, setChartLoadingData, setChartData, setEmptyChartData } from '../../../actions';
import localStorageUtil from "../../../utils/localStorageUtil"
import { makeParallelRequest, proccessPromises, unifyRequestsResults } from "../../../utils/chartsParallelRequest"

//const customData  = require('../../../config.json')
const customData = window.Configs;

/*
const data = [
    {
        name: '1', luminosity: 2400
    },
    {
        name: '2', luminosity: 1398
    },
    {
        name: '3', luminosity: 9800
    }
];
*/

const Movements = ({ group, datamov, _setGroup, _setMovementsData, _setEmptyChartData, _setChartLoadingData }) => {
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState(useHistory());
    const [datachart, setDataChart] = useState([]);
    const [datachartLOCAL, setDataChartLOCAL] = useState([]);
    const [load, setLoad] = useState(false);


    const loadDataChart = async () => {
        //window.CancelTokenMotion = axios.CancelToken;
        //window.sourceMotion = window.CancelTokenMotion.source();


        setLoading(true)
        _setChartLoadingData({ sensor: "motion", data: true })

        const interval = 0.5 //in hours
        const start = moment.utc().subtract(customData.BAR_MOTION_CHART, 'hours')
        const currentTime = moment.utc()

        const promises = makeParallelRequest('motion', interval, customData.BAR_MOTION_CHART, currentTime, group.uuid)
        const fetch_data = await proccessPromises(promises)
        const result = unifyRequestsResults(fetch_data)

        if (result.error) {
            if (result.auth_error) {
                message.info(result.message)
                logout();
            } else {
                message.info(result.message)
            }
        } else {
            const datavalue = result.datavalue

            const data = datavalue //.data;
            let newdatachart = [];

            let tmphour = (Number)(start.format('HH'))
            let currenthour = (Number)(currentTime.format('HH'))

            for (const year in data) {
                if (data.hasOwnProperty(year)) {
                    for (const mounth in data[year]) {
                        if (data[year].hasOwnProperty(mounth)) {
                            for (const day in data[year][mounth]) {
                                if (data[year][mounth].hasOwnProperty(day)) {
                                    for (const hour in data[year][mounth][day]) {
                                        if (data[year][mounth][day].hasOwnProperty(hour)) {
                                            //fill with 0 bettewen
                                            let i = 0;
                                            while (tmphour !== (Number)(hour) && i <= customData.BAR_MOTION_CHART) {
                                                newdatachart.push({ name: tmphour + "", movements: "0" });
                                                tmphour = (tmphour + 1) % 24;
                                                i++;
                                            }
                                            const average = (data[year][mounth][day][hour]['data']) / ((data[year][mounth][day][hour]['count']) * interval)
                                            newdatachart.push({ name: hour, movements: average.toFixed(2) });
                                            tmphour = (tmphour + 1) % 24;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //fill with 0 end
            let i = 0;
            while (tmphour !== (Number)((currenthour + 1) % 24) && i <= customData.BAR_MOTION_CHART) {
                newdatachart.push({ name: tmphour + "", movements: "0" });
                tmphour = (tmphour + 1) % 24;
                i++;
            }

            if (newdatachart.length > customData.BAR_MOTION_CHART) {
                const thedata = newdatachart.slice(newdatachart.length - customData.BAR_MOTION_CHART, newdatachart.length)
                await _setMovementsData({ sensor: "motion", data: thedata })
                const newlastdatachart = ({ name: thedata[thedata.length - 1].name + "", movements: thedata[thedata.length - 1].movements + "" });
                await _setMovementsData({ sensor: "lastmotion", data: newlastdatachart });
            }
        }
        setLoad(true)
        _setChartLoadingData({ sensor: "motion", data: false })
        setLoading(false)
    }

    const updateDataChart = async (datachartparam) => {

        const uuid = group.uuid
        let currentTime = moment.utc()
        const hour = (Number)(currentTime.format('HH')) //get current hour
        const lasthour = (Number)(datachartparam[datachartparam.length - 1].name) //get last hour

        let diff = 0
        if (hour >= lasthour) {
            diff = hour - lasthour
        } else {
            diff = hour + (24 - lasthour)
        }

        diff = diff + 1

        //const start = moment.utc().subtract(diff, 'hours')
        currentTime = moment.utc()

        const interval = 0.5 //in hours

        const promises = makeParallelRequest('motion', interval, diff, currentTime, group.uuid)

        const fetch_data = await proccessPromises(promises)

        //console.log("fetch_data", fetch_data)

        const result = unifyRequestsResults(fetch_data)

        if (result.error) {
            if (result.auth_error) {
                message.info(result.message)
                logout();
            } else {
                message.info(result.message)
            }
        } else {

            const datavalue = result.datavalue
            const data = datavalue //.data;

            //console.log('TCL: GroupSensorChart -> data', data)
            let emptychart = []
            for (let index = 0; index < customData.BAR_MOTION_CHART; index++) {
                emptychart.push({ name: (hour - customData.BAR_MOTION_CHART + index + 1 + 24) % 24 + "", movements: "0" });
            }

            for (let index = 0; index < emptychart.length; index++) {
                for (let indey = 0; indey < datachart.length; indey++) {
                    if (emptychart[index].name === datachart[indey].name) {
                        emptychart[index] = datachart[indey]
                    }
                }
            }

            for (const year in data) {
                if (data.hasOwnProperty(year)) {
                    for (const mounth in data[year]) {
                        if (data[year].hasOwnProperty(mounth)) {
                            for (const day in data[year][mounth]) {
                                if (data[year][mounth].hasOwnProperty(day)) {
                                    for (const hour in data[year][mounth][day]) {
                                        if (data[year][mounth][day].hasOwnProperty(hour)) {
                                            for (let index = 0; index < emptychart.length; index++) {
                                                //console.log(data[year][mounth][day][hour])
                                                if (emptychart[index].name === hour && ((lasthour - 1 + 24) % 24 !== (Number)(hour))) {
                                                    const average = (data[year][mounth][day][hour]['data']) / ((data[year][mounth][day][hour]['count']) * interval)
                                                    emptychart[index].movements = average.toFixed(2)
                                                    //emptychart[index].movements = (data[year][mounth][day][hour]).toFixed(2)
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let thedata = emptychart

            if (emptychart.length > customData.BAR_MOTION_CHART) {
                thedata = emptychart.slice(emptychart.length - customData.BAR_MOTION_CHART, emptychart.length)
            }
            if (uuid === group.uuid) {
                await _setMovementsData({ sensor: "motion", data: thedata })
                const newlastdatachart = ({ name: thedata[thedata.length - 1].name + "", movements: thedata[thedata.length - 1].movements + "" });
                await _setMovementsData({ sensor: "lastmotion", data: newlastdatachart });
            }
        }

    }

    useEffect(() => {
        if (group.group_type === 'Control') {
            if (window.motionInterval !== undefined && window.motionInterval !== 'undefined') {
                window.clearInterval(window.motionInterval);
            }

            window.motionInterval = setInterval(() => {
                if (datamov) {
                    if (group.uuid && datamov.length > 0 && load && !loading) {
                        updateDataChart(datachart)
                    }
                }
            }, customData.UPDATE_CHART);

            return () => {
                clearInterval(window.motionInterval);
            };
        }
    }, [group.uuid, loading, datachart])

    useEffect(() => {
        if (group.group_type === 'Control') {
            if (datamov) {
                const offset = new Date().getTimezoneOffset() / 60;
                //console.log(offset);
                const datalocal = datamov.map(function (item) {
                    const hour = ((Number)(item['name']) - offset + 24) % 24
                    let hour_12 = parseInt(hour) % 12
                    let time_label = " AM"
                    if (hour_12 == 0) {
                        hour_12 = 12
                    }
                    if (parseInt(hour) >= 12) {
                        time_label = " PM"
                    }
                    return { "name": "" + hour_12 + time_label, "Movements": parseFloat(item['movements']) };
                });

                setDataChartLOCAL(datalocal)
                setDataChart(datamov)
            }
        }
    }, [datamov])

    useEffect(() => {
        if (group.group_type === 'Control') {
            if (window.motionInterval !== undefined && window.motionInterval !== 'undefined') {
                window.clearInterval(window.motionInterval);
            }
            if (group.uuid) {
                loadDataChart()
            }
        } else {
            let currentTime = moment.utc()
            const hour = (Number)(currentTime.format('HH')) //get current hour
            let arrayempty = [];
            var offset = new Date().getTimezoneOffset() / 60
            for (let index = 0; index < customData.BAR_POWER_CHART; index++) {
                const element = (parseInt(hour) - customData.BAR_POWER_CHART + index + 1 + 24) % 24;
                arrayempty.push({ 'name': element, 'power': 0 });
            }
            const datalocal = arrayempty.map(function (item) {
                const hour = ((Number)(item['name']) - offset + 24) % 24
                let hour_12 = parseInt(hour) % 12
                let time_label = " AM"
                if (hour_12 == 0) {
                    hour_12 = 12
                }
                if (parseInt(hour) >= 12) {
                    time_label = " PM"
                }
                return { "name": "" + hour_12 + time_label, "Movements": parseFloat(item['power']) };
            });
            setDataChartLOCAL(datalocal)
            setDataChart(arrayempty)
        }
    }, [group.uuid])


    const logout = async () => {
        await _setGroup({})
        await _setEmptyChartData()
        localStorageUtil.clearAppStorage();
        history.push("/login")
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          return (
            <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" >
              <div className="recharts-default-tooltip" style={{ margin: "0px", padding: "10px", backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap" }}>
                <p className="label" style={{ marginBottom: "0px" }}>{`${label}`}</p>
                <p className="intro" style={{ color: "rgb(0, 129, 143)", marginBottom: "0px" }}>{`Movements:  ${payload && payload.length > 0 ? payload[0].value : 0}`}</p>
              </div>
            </div>
          );
        }
    
        return null;
      };

    return (loading ? (
        <CenteredRow typeClass="screen" >
            <span className={"icon-animated"}>
                <Icon
                    type="loading"
                    style={{ color: "#287d8d", fontSize: "50px" }}
                />
            </span>
        </CenteredRow>
    ) : (
            <div className="movements" style={{ width: '100%', maxWidth: 250, height: 150, margin: 'auto' }}>
                <ResponsiveContainer>
                    <BarChart
                        width={300}
                        height={150}
                        data={datachartLOCAL}
                        margin={{
                            top: 5, right: 5, left: 5, bottom: 0,
                        }}
                        barGap={8}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#287d8e" stopOpacity={1} />
                                <stop offset="95%" stopColor="#b4dbf7" stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name" tickLine={false} axisLine={false} />
                        <YAxis tick={false} width={0} />
                        <Tooltip content={<CustomTooltip />}/>
                        <Bar dataKey="Movements" fill="url(#colorUv)" maxBarSize={15} />

                    </BarChart>
                </ResponsiveContainer>
                <div className="label-charts">Movements for Last 8 Hours</div>
            </div>
        ))
}

const mapStateToProps = state => {
    return {
        group: state.user.group,
        gloading: state.user.loading,
        chartloading: state.user.chartloading.motion,
        datamov: state.user.chartdata.motion
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        _setGroup: data => {
            dispatch(setGroup(data));
        },
        _setChartLoadingData: (data) => {
            dispatch(setChartLoadingData(data));
        },
        _setEmptyChartData: () => {
            dispatch(setEmptyChartData());
        },
        _setMovementsData: data => {
            dispatch(setChartData(data));
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Movements);