import React, { useEffect, useState } from "react"
import localStorageUtil from "../../utils/localStorageUtil"
import { Form, Button, message, Switch, Collapse } from "antd"
import { withRouter } from "react-router-dom"
import User2faQr from "../User2faQr"
import { loadUserProfile, updateUserProfile } from '../../api'
import "./styles.css"

const { Panel } = Collapse

const FormItem = Form.Item;

const UserProfileForm = ({ form, setIsModalVisible }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [userProfileData, setUserProfileData] = useState(null)

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, isFieldTouched } = form

  useEffect(
    () => {
      (async () => {
        resetFields()
        const uuid = localStorageUtil.get('user_uuid')
        const datavalue = await loadUserProfile(uuid)

        if (datavalue.error) {
          message.error(datavalue.message);
        } else {
          const data = datavalue.data
          setUserProfileData(data)
        }
        setIsLoading(false)
      })()
    },
    []
  )

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { active_2fa } = values
        const form = {}

        if (isFieldTouched("active_2fa")) {
          form.active_2fa = active_2fa
        }

        const uuid = localStorageUtil.get('user_uuid')
        const datavalue = await updateUserProfile(uuid, form.active_2fa)

        if (datavalue.error) {
          message.error(datavalue.message);
          setIsLoading(false);
        } else {
          const data = datavalue.data
          
          setUserProfileData(data)
          setIsLoading(false)
          message.success("Updated user profile")
        }
      } else {
        message.error(err);
      }
    })
  }

  const handleCancel = (e) => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>

        <FormItem label="Two-Factor Authentication">
          {getFieldDecorator("active_2fa", {
            initialValue: userProfileData ? userProfileData.active_2fa : false,
            valuePropName: 'checked'
          })(
            <Switch disabled={isLoading} />
          )}
        </FormItem>

        {userProfileData && userProfileData.active_2fa && (
          <div>
            <Collapse ghost>
              <Panel header="Check your 2fa QR Code" key="1">
                <User2faQr isLogin={false} />
              </Panel>
            </Collapse>
            <hr />
          </div>
        )}

        <FormItem style={{ textAlign: "center", marginTop: "5%" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "40%" }}
            size="large"
            icon={"upload"}
            loading={isLoading}
          >
            {" Save"}
          </Button>
          <Button
            type="default"
            htmlType="button"
            onClick={handleCancel}
            icon={"close"}
            style={{ width: "40%", marginLeft: "1em" }}
            size="large"
          >
            Cancel
          </Button>
        </FormItem>
      </Form>
    </>
  )
};

const WrappedUserProfileForm = Form.create()(UserProfileForm);

export default withRouter(WrappedUserProfileForm);
