import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col, Card, Form, Icon, Input, Button, message } from 'antd';
import { useHistory } from "react-router-dom";

import { requestPasswordReset } from '../../../actions'
import { passwordForgot } from '../../../api'

import "./style.css";


const ForgotPasswordForm = ({ form, requestPasswordReset }) => {
  const [loading, setLoading] = useState(false);
  const [history,] = useState(useHistory());

  //const customData = require('../../config.json')
  const customData = window.Configs;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      setLoading(true)
      if (!err) {
        const { email } = values;

        requestPasswordReset({ email })

        const datavalue = await passwordForgot(email)

        if (datavalue.error) {
          message.error(datavalue.message);
          setLoading(false);
        } else {
          const data = datavalue.data

          resetFields()

          setLoading(false)
          message.success("Request successfully. Check email for reset token")
          history.push('/reset_password')
        }

      } else {
        message.error(err);
        setLoading(false);
      }
    });
  };

  const { getFieldDecorator, validateFields, resetFields } = form;
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", backgroundColor: "#cbdce9" }}
    >
      <Col xs={20} sm={14} md={12} lg={10} xl={7}>
        <Card>
          <h3>Input your user email to request a password reset:</h3>
          <Form onSubmit={handleSubmit} className="login-form" style={{margin:"auto"}}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input your email!' }],
              })(
                <Input
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />,
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ backgroundColor: '#287d8e', width: "100%" }}
                loading={loading}
                onClick={() => handleSubmit}>
                Request password reset
              </Button>
            </Form.Item>
          </Form>
          <div>Already had a token?, then<span> </span>
            <Link to="/reset_password">
              <a
                type="link"
                disabled={loading}
              >
                click here!
              </a>
            </Link>
          </div>
          <Row
            type="flex"
            justify="center"
            align="middle">
            <Link to="/login">
              <Button
                type="default"
                style={{ width: "100%", marginTop: "2em" }}
                size="large"
                loading={loading}
              >
                Cancel
          </Button>
            </Link>
          </Row>
        </Card>
      </Col>
    </Row>
  );

}

const WrappedForgotPasswordForm = Form.create({ name: 'forgot_password_form' })(ForgotPasswordForm);

const mapDispatchToProps = (dispatch) => {
  return {
    requestPasswordReset: form => {
      dispatch(requestPasswordReset(form))
    },
  };
};
export default connect(
  null,
  mapDispatchToProps
)(WrappedForgotPasswordForm);
