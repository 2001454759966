import React, { useState } from "react"
import { Form, Input, Button, message, Card, Icon, Row, Col, Collapse } from "antd"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import User2faQr from "../../components/User2faQr"
import localStorageUtil from "../../utils/localStorageUtil"

import "./style.css"

import { loginUser, getCompanies } from '../../actions';
import { setAuthToken } from '../../services'
import { login2faSecondStep } from '../../api'

const { Panel } = Collapse

const FormItem = Form.Item;

const Login2faSecondStepForm = ({
  form,
  history,
  loginUser,
  getCompanies,
}) => {
  const [loading, setLoading] = useState(false)
  

  const { getFieldDecorator, validateFields, resetFields } = form;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      if (!err) {
        const { verification_code } = values
        const username = localStorageUtil.get('username')
        const datavalue = await login2faSecondStep(username, verification_code)

        if (datavalue.error) {
          message.error(datavalue.message);
          setLoading(false);
        } else {
          const data = datavalue.data
      
          loginUser({ username: data.username, access_token: data.access_token, refresh_token: data.refresh_token, modules: data.modules, uuid: data.uuid });
          // save in local storage
          localStorageUtil.setToken(data.access_token);
          localStorageUtil.setRefreshToken(data.refresh_token)
          localStorageUtil.set(data.username + "", 'username')
          localStorageUtil.set(data.uuid + "", 'user_uuid')

          // update headers of ajax request
          setAuthToken(data.access_token);
          resetFields();

          await getCompanies(data.companies);

          setLoading(false)
          history.push('/')
        }
      } else {
        message.error(err)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh", backgroundColor: "#fff" }}
      >
        <Col xs={20} sm={14} md={12} lg={10} xl={7}>
          <Card>
            <h3>Input your Google Authenticator verification code:</h3>
            <Form onSubmit={handleSubmit} className="login-form" style={{margin:"auto"}}>
              <FormItem>
                {getFieldDecorator('verification_code', {
                  rules: [{ required: true, message: 'Please input your verification code!' }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Verification code"
                  />,
                )}
              </FormItem>
              <FormItem style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Authenticate
          </Button>
              </FormItem>
            </Form>

            <br />
            <Collapse ghost>
              <Panel header="You don't have register this site on your Google Authenticator?, click here!!!" key="1">
                <User2faQr isLogin={true} />
              </Panel>
            </Collapse>

            <Row
              type="flex"
              justify="center"
              align="middle">
              <Link to="/login">
                <Button
                  type="default"
                  style={{ width: "100%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Cancel
          </Button>
              </Link>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

const WrappedLogin2faSecondStepForm = Form.create()(Login2faSecondStepForm);

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: form => {
      dispatch(loginUser(form));
    },
    getCompanies: companies => {
      dispatch(getCompanies(companies));
    },
  };
};
export default connect(
  null,
  mapDispatchToProps
)(WrappedLogin2faSecondStepForm)