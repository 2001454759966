export const loginUser = (data) => ({
  type: 'USER_LOGIN',
  payload: data
}
)

export const login2faFirstStep = (data) => ({
  type: 'USER_LOGIN_2FA_FIRST_STEP',
  payload: data
}
)

export const requestPasswordReset = (data) => ({
  type: 'REQUEST_PASSWORD_RESET',
  payload: data
}
)

export const resetUserPassword = (data) => ({
  type: 'RESET_USER_PASSWORD',
  payload: data
}
)

export const getCompanies = (data) => {
  return {
    type: 'GET_COMPANIES',
    payload: data
  }
}

export const setGroup = (data) => {
  return {
    type: 'SET_GROUP',
    payload: data
  }
}

export const setLightCapability = (data) => {
  return {
    type: 'SET_LIGHTCAPABILITY',
    payload: data
  }
}

export const setLoading = (data) => {
  return {
    type: 'SET_LOADING',
    payload: data
  }
}

export const setCurrentTab = (data) => {
  return {
    type: 'SET_TAB',
    payload: data
  }
}

export const setCurrentSensor = (data) => {
  return {
    type: 'SET_SENSORTYPE',
    payload: data
  }
}


//TAB CONTROL
//UPDATED OVERRIDE
export const setOnDetectOffOverride = (data) => {
  return {
    type: 'SET_ONDETECTOFF_OVERRIDE',
    payload: data
  }
}

export const setLightLevelOverride = (data) => {
  return {
    type: 'SET_LIGHTLEVEL_OVERRIDE',
    payload: data
  }
}

export const setWarmWhiteLevelOverride = (data) => {
  return {
    type: 'SET_WARMWHITELEVEL_OVERRIDE',
    payload: data
  }
}

export const setBlueOnOffOverride = (data) => {
  return {
    type: 'SET_BLUEONOFF_OVERRIDE',
    payload: data
  }
}

export const setCircadianOverride = (data) => {
  return {
    type: 'SET_CIRCADIAN_OVERRIDE',
    payload: data
  }
}


//TAB SCHEDULE
export const setSchedule = (data) => {
  return {
    type: 'SET_SCHEDULE',
    payload: data
  }
}


export const setScheduleLevel = (data) => {
  return {
    type: 'SET_SCHEDULELEVEL',
    payload: data
  }
}

export const setScheduleWhiteLevel = (data) => {
  return {
    type: 'SET_SCHEDULE_WHITE_LEVEL',
    payload: data
  }
}

/*
export const getScheduleLevel = (data) => {
  return {
    type: 'GET_SCHEDULELEVEL',
    payload: data
  }
}
*/


export const setScheduleAllPeriod = (data) => {
  return {
    type: 'SET_SCHEDULEALLPERIOD',
    payload: data
  }
}

export const setSchedulePeriod = (data) => {
  return {
    type: 'SET_SCHEDULEPERIOD',
    payload: data
  }
}

/*
export const getSchedulePeriod = (data) => {
  return {
    type: 'GET_SCHEDULEPERIOD',
    payload: data
  }
}
*/

export const addSchedulePeriod = (data) => {
  return {
    type: 'ADD_SCHEDULEPERIOD',
    payload: data
  }
}

export const delSchedulePeriod = (data) => {
  return {
    type: 'DEL_SCHEDULEPERIOD',
    payload: data
  }
}


//TAB DATA
export const setSensorData = (data) => {
  return {
    type: 'SET_SENSORDATA',
    payload: data
  }
}

//GET SENSOR DATA
export const setChartLoadingData = (data) => {
  return {
    type: 'SET_CHARTLOADINGDATA',
    payload: data
  }
}

//GET SENSOR DATA
export const setEmptyChartData = () => {
  return {
    type: 'SET_CHARTDATA',
    payload: {}
  }
}

//GET SENSOR DATA
export const setChartData = (data) => {
  return {
    type: 'SET_CHARTSENSORDATA',
    payload: data
  }
}


//TAB ADVANCED SCHEDULE
export const setAdvancedScheduleEnabled = (data) => {
  return {
    type: 'SET_ADVANCED_SCHEDULE_ENABLED',
    payload: data
  }
}

export const setAdvancedSchedule = (data) => {
  return {
    type: 'SET_ADVANCED_SCHEDULE',
    payload: data
  }
}

export const addAdvancedSchedule = (data) => {
  return {
    type: 'ADD_ADVANCED_SCHEDULE',
    payload: data
  }
}

export const delAdvancedSchedule = (data) => {
  return {
    type: 'DEL_ADVANCED_SCHEDULE',
    payload: data
  }
}


export const setAdvancedScheduleLevel = (data) => {
  return {
    type: 'SET_ADVANCED_SCHEDULE_LEVEL',
    payload: data
  }
}

export const setAdvancedScheduleWhiteLevel = (data) => {
  return {
    type: 'SET_ADVANCED_SCHEDULE_WHITE_LEVEL',
    payload: data
  }
}


export const setAdvancedScheduleAllPeriod = (data) => {
  return {
    type: 'SET_ADVANCED_SCHEDULE_ALL_PERIOD',
    payload: data
  }
}

export const setAdvancedSchedulePeriod = (data) => {
  return {
    type: 'SET_ADVANCED_SCHEDULE_PERIOD',
    payload: data
  }
}

export const addAdvancedSchedulePeriod = (data) => {
  return {
    type: 'ADD_ADVANCED_SCHEDULE_PERIOD',
    payload: data
  }
}

export const delAdvancedSchedulePeriod = (data) => {
  return {
    type: 'DEL_ADVANCED_SCHEDULE_PERIOD',
    payload: data
  }
}


//notification
export const setUserNotification = (data) => {
  return {
    type: 'SET_USER_NOTIFICATION',
    payload: data
  }
}

export const setNotificationReceipt = (data) => {
  return {
    type: 'SET_NOTIFICATION_RECEIPT',
    payload: data
  }
}

export const addNewNotifications = (data) => {
  return {
    type: 'ADD_NEW_NOTIFICATIONS',
    payload: data
  }
}

export const setToastNotification = (data) => {
  return {
    type: 'SET_TOAST_NOTIFICATION',
    payload: data
  }
}

export const removeToastNotification = (data) => {
  return {
    type: 'REMOVE_TOAST_NOTIFICATION',
    payload: data
  }
}

// user saved configurations actions
export const setUserSavedConfigurations = (data) => {
  return {
    type: 'SET_USER_SAVED_CONFIGURATIONS',
    payload: data
  }
}

export const deleteUserSavedConfigurationAction = (config_uuid) => {
  return {
    type: 'DELETE_USER_SAVED_CONFIGURATION',
    payload: {config_uuid}
  }
}

export const activatedUserSavedConfigurationAction = (data) => {
  return {
    type: 'ACTIVATE_USER_SAVED_CONFIGURATION',
    payload: data
  }
}

export const updateUserSavedConfigurationAction = (data) => {
  return {
    type: 'UPDATE_USER_SAVED_CONFIGURATION',
    payload: data
  }
}

export const createUserSavedConfigurationAction = (data) => {
  return {
    type: 'CREATE_USER_SAVED_CONFIGURATION',
    payload: data
  }
}