export const groupTypes = {
  CONTROL: 'Control',
  LOGICAL: 'Logical',
  MIX: 'Mix',
}

export const treeNodeTypes = {
  BUILDING_GROUP: 'buildingGroup',
  SUB_GROUP: 'subGroup',
}

export const sensorDataTypeIds = {
  POWER: 1,
  //LIGHT: 2,
  //IR: 3,
  TEMPERATURE: 4,
  HUMIDITY: 5,
  PRESSURE: 6,
  MOTION: 7,
}

export const sensorDataTypes = {
  TEMPERATURE: 'temperature',
  HUMIDITY: 'humidity',
  PRESSURE: 'pressure',
  //LIGHT: 'lux',
  //IR: 'ir',
  // MOTION: 'motion',
  // POWER: 'power',
  CO2: 'co2',
  VOC: 'voc',
}

export const tabs = {
  CONTROL: '1',
  SCHEDULE: '2',
  DEVICE: '3',
  DATA: '4',
  ADVANCED_SCHEDULE: '5'
}