import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { message } from 'antd';
import {
    PieChart, Pie, Cell, ResponsiveContainer, Label
} from 'recharts';


import { Icon } from 'antd';
import moment from 'moment';
import CenteredRow from "../../../components/CenteredRow";

import { setGroup, setChartData, setEmptyChartData } from '../../../actions';
import { loadChartSensorDataPower } from "../../../api";
import localStorageUtil from "../../../utils/localStorageUtil";

const customData = window.Configs;

/*
const data02 = [
    { name: 'A1', value: 76 },
    { name: 'A2', value: 24 },
];
*/

let COLORS = ['#287d8e', '#ced8de'];

const MaxPower = ({ group, chartloading, lastdatapower, _setGroup, _setPowerData, _setEmptyChartData }) => {
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState(useHistory());
    const [lastdatachart, setLastDataChart] = useState({});
    const [lastdatachartLOCAL, setLastDataChartLOCAL] = useState([
        { name: '', value: 0 },
        { name: '', value: 0 }]);
    const [load, setLoad] = useState(false);

    const loadDataChart = async (initload) => {
        //window.CancelTokenPower = axios.CancelToken;
        //window.sourcePower = window.CancelTokenPower.source();

        const start = moment.utc().subtract(1, 'hours')
        const currentTime = moment.utc()
        if (initload) {
            setLoading(true)
        }
        const datavalue = await loadChartSensorDataPower(
            group.uuid,
            'power',
            start.format('YYYYMMDDHHmmss'),
            currentTime.format('YYYYMMDDHHmmss'),
        );
        if (datavalue.error) {
            if (datavalue.autherror) {
                message.info(datavalue.message);
                logout();
            } else {
                message.error(datavalue.message);
            }
        } else {

            const data = datavalue.data;
            //console.log('TCL: GroupSensorChart -> data', data)

            let currenthour = (Number)(currentTime.format('HH'))
            let newlastdatachart = ({ name: currenthour + "", power: "0" });

            for (const year in data) {
                if (data.hasOwnProperty(year)) {
                    for (const mounth in data[year]) {
                        if (data[year].hasOwnProperty(mounth)) {
                            for (const day in data[year][mounth]) {
                                if (data[year][mounth].hasOwnProperty(day)) {
                                    for (const hour in data[year][mounth][day]) {
                                        if (data[year][mounth][day].hasOwnProperty(hour)) {
                                            //fill with 0 bettewen
                                            if (currenthour === (Number)(hour)) {
                                                newlastdatachart = ({ name: currenthour + "", power: (data[year][mounth][day][hour]).toFixed(2) });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            _setPowerData({ sensor: "lastpower", data: newlastdatachart })
        }

        setLoad(true)
        if (initload) {
            setLoading(false)
        }
    }

    /*
    useEffect(() => {
        if (window.lastpowerInterval !== undefined && window.lastpowerInterval !== 'undefined') {
            window.clearInterval(window.lastpowerInterval);
        }

        window.lastpowerInterval = setInterval(() => {
            if (lastdatapower) {
                if (group.uuid && load && !loading) {
                    loadDataChart(false)
                }
            }
        }, customData.UPDATE_CHART);

        return () => {
            clearInterval(window.lastpowerInterval);
        };

    }, [group.uuid, loading])
    */

    useEffect(() => {
        if (group.group_type === 'Control') {
            if (lastdatapower) {
                const value = (Number)(lastdatapower['power'])
                let percent = ((value / (customData.MAXPOWER * group.controllers.length)) * 100).toFixed(0)

                if (percent >= 100) {
                    percent = 100;
                    COLORS[0] = '#c00';
                } else {
                    COLORS[0] = '#287d8e';
                }
                const newlastdatalocal = [
                    { name: 'Power', value: Number(percent) },
                    { name: 'Rest', value: 100 - percent },
                ];

                setLastDataChartLOCAL(newlastdatalocal)
                setLastDataChart(lastdatapower)
            }
        }
    }, [lastdatapower])

    /*
    useEffect(() => {
        if (window.lastpowerInterval !== undefined && window.lastpowerInterval !== 'undefined') {
            window.clearInterval(window.lastpowerInterval);
        }
        if (group.uuid) {
            loadDataChart(true)
        }
    }, [group.uuid])
    */

    useEffect(() => {
        setLoading(chartloading)
    }, [chartloading])


    const logout = async () => {
        await _setGroup({})
        await _setEmptyChartData()
        localStorageUtil.clearAppStorage();
        history.push("/login")
    }

    return (loading ? (
        <CenteredRow typeClass="screen" >
            <span className={"icon-animated"}>
                <Icon
                    type="loading"
                    style={{ color: "#287d8d", fontSize: "50px" }}
                />
            </span>
        </CenteredRow>
    ) : (
            <div style={{ width: 150, height: 150, margin: 'auto' }}>
                <ResponsiveContainer>
                    <PieChart width={150} height={150}>
                        <Pie
                            data={lastdatachartLOCAL}
                            dataKey="value"
                            cx={70}
                            cy={70}
                            innerRadius={50}
                            outerRadius={65}
                            startAngle={90}
                            endAngle={450}
                            fill="#287d8e"
                            labelLine={false}
                        >
                            {
                                lastdatachartLOCAL.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                            }
                            {"power" in lastdatachart && (
                                <Label className="label-center-piechart" value={lastdatapower ? lastdatapower['power']+" Wh" : "0"} fill={'#555'} position="center" />
                            )}       
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <div className="label-charts">Power for Last Hour</div>
            </div>
        ))
}

const mapStateToProps = state => {
    return {
        group: state.user.group,
        gloading: state.user.loading,
        chartloading: state.user.chartloading.power,
        lastdatapower: state.user.chartdata.lastpower
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        _setGroup: data => {
            dispatch(setGroup(data));
        },
        _setEmptyChartData: () => {
            dispatch(setEmptyChartData());
        },
        _setPowerData: data => {
            dispatch(setChartData(data));
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaxPower);