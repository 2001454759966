import React, { useState, useEffect } from "react";
import { Slider, Radio, message, Button, Modal, Icon, Row, Col } from "antd";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import localStorageUtil from "../../../utils/localStorageUtil";
import { updatedBLUEONOFFOverride, updatedONDETECTOFFOverride, updatedLightLevelOverride, updatedWarmWhiteLevel, updatedCircadianRhythmOverride } from "../../../api"

import { setGroup, setBlueOnOffOverride, setOnDetectOffOverride, setCircadianOverride, setLightLevelOverride, setWarmWhiteLevelOverride, setLoading, setEmptyChartData } from '../../../actions';
import { lightColorTemperatureFormatter } from "../../../utils/lightLevelsColorTemperature"
import { Holdable, defineHold } from 'react-touch';
import moment from 'moment';
import "./style.css";

const Control = ({ group, light_capability, _setGroup, _setBlueOnOff, _setCircadianOnOff, _setOnDetectOff, _setLightLevel, _setWarmWhiteLevel, _setGLoading, _setEmptyChartData }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState(useHistory());
  const [sliderbrightvalue, setSliderBrightValue] = useState(group.slider_level);
  const [sliderBrightOverridenValue, setSliderBrightOverridenValue] = useState(group.overriden_slider_level)
  const [brightValueInheritance, setBrightValueInheritance] = useState(group.slider_level_inheritance)
  const [slidertemperaturevalue, setSliderTemperatureValue] = useState(group.white_level);
  const [sliderTemperatureOverridenValue, setSliderTemperatureOverridenValue] = useState(group.overriden_white_level)
  const [temperatureValueInheritance, setTemperaureValueInheritance] = useState(group.color_level_inheritance)
  //const [onoff, setonoff] = useState(group.on);
  const [onmode, setonmode] = useState(group.on_mode)
  const [onModeInheritance, setOnModeInheritance] = useState(group.on_inheritance)
  const [occupancy_sensor, setOccupancySensor] = useState(group.occupancy_sensor)

  const [lightcapability, setLightcapability] = useState(light_capability)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [maxTimeON, setMaxTimeON] = useState(0);
  const [maxTimeOFF, setMaxTimeOFF] = useState(0);

  const [bluemode, setBluemode] = useState(group.blue_light_boost)
  const [blueminutes, setBlueMinutes] = useState(group.blue_light_boost_minutes)
  const [bluetimestamp, setBluetimestamp] = useState(group.blue_light_boost_timestamp)

  const [blueTime, setBlueTime] = useState(0)

  const [circadianMode, setCircadianMode] = useState(group.circadian_rhythm)
  const [circadianStartTime, setCircadianStartTime] = useState(group.circadian_start_date_time)

  const [circadianTime, setCircadianTime] = useState("")


  useEffect(() => {
    setSliderBrightValue(group.slider_level);
    setSliderBrightOverridenValue(group.overriden_slider_level)
    setBrightValueInheritance(group.slider_level_inheritance)
    setSliderTemperatureValue(group.white_level)
    setSliderTemperatureOverridenValue(group.overriden_white_level)
    setTemperaureValueInheritance(group.color_level_inheritance)
    setonmode(group.on_mode)
    setOnModeInheritance(group.on_inheritance)
    setOccupancySensor(group.occupancy_sensor)
    setMaxTimeON(group.max_on_secs)
    setMaxTimeOFF(group.max_off_secs)

    setBluemode(group.blue_light_boost)
    setBlueMinutes(group.blue_light_boost_minutes)
    setBluetimestamp(group.blue_light_boost_timestamp)

    setCircadianMode(group.circadian_rhythm)
    setCircadianStartTime(group.circadian_start_date_time)


    setLightcapability(light_capability)
  }, [group.uuid, group.on_mode, group.on_inheritance, group.slider_level, group.overriden_white_level, group.overriden_slider_level, group.white_level, group.occupancy_sensor, group.max_on_secs, group.max_off_secs, light_capability, group.blue_light_boost, group.blue_light_boost_minutes, group.blue_light_boost_timestamp, group.circadian_rhythm, group.circadian_start_date_time])



  const calcdiff = () => {
    if (group.blue_light_boost) {
      if (bluetimestamp && blueminutes) {
        const strdate = (bluetimestamp).replace("T", " ");
        const current_date = moment.utc();
        //convert
        const get_date = moment.utc(strdate).add(blueminutes, "minutes");
        const local = get_date.format("YYYY-MM-DD HH:mm:ss");
        const diff = get_date.diff(current_date, 'minutes');
        if (diff >= 0) {
          setBlueTime(diff)
        } else {
          setBlueTime(0)
          //setBLUEONOFF(false)
        }
      } else {
        setBlueTime(0)
      }
    }
  }

  const calcCircadianLeftTime = () => {
    if (group.circadian_rhythm) {
      if (circadianStartTime) {
        /*const circadian_start_date_time = moment.utc(circadianStartTime).local()
        circadian_start_date_time.add(12, 'hours')
        const totalSeconds = circadian_start_date_time.diff(moment.utc(), 'seconds')
        setCircadianTime(moment().startOf('day').seconds(totalSeconds).format('H:mm'))
        */
        setCircadianTime(moment.utc(circadianStartTime).local().format('H:mm'))
      } else {
        setCircadianTime("")
      }
    }
  }

  useEffect(() => {
    calcCircadianLeftTime()
  }, [circadianMode, circadianStartTime]);

  useEffect(() => {
    calcdiff();
    const interval = setInterval(_ => {
      calcdiff();
    }, 60000);
    return _ => clearInterval(interval);
  }, [bluemode, blueminutes, bluetimestamp]);

  const setONDETECTOFF = async (value, holder = false, max_time = 0) => {
    setLoading(true);
    _setGLoading(true);
    const on = (value === "on");
    const shutdown = (value === "shutdown");
    let max_on_secs = group.max_on_secs;
    if (holder && value === "on") {
      max_on_secs = max_time;
    }
    let max_off_secs = group.max_off_secs;
    if (holder && value === "shutdown") {
      max_off_secs = max_time;
    }
    const datavalue = await updatedONDETECTOFFOverride(group.uuid, on, shutdown, max_on_secs, max_off_secs);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      //setonmode(value);
      //setOnModeInheritance("own")
      await _setOnDetectOff(datavalue.data);
    }
    setLoading(false);
    _setGLoading(false);
  }

  const changeLightLevel = async (slider_level) => {
    setLoading(true);
    _setGLoading(true);
    const form = { "slider_level": slider_level }

    const datavalue = await updatedLightLevelOverride(group.uuid, form);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      //setSliderBrightValue(slider_level)
      //setSliderBrightOverridenValue(datavalue.data.overriden_slider_level)
      //setBrightValueInheritance("own")
      await _setLightLevel(datavalue.data);
    }
    setLoading(false);
    _setGLoading(false);
  }

  const changeWarmLevel = async (white_level) => {
    setLoading(true);
    _setGLoading(true);
    const warm_white_level = 100 - white_level
    const form = { "white_level": white_level, "warm_white_level": warm_white_level }
    const datavalue = await updatedLightLevelOverride(group.uuid, form)
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      //setSliderTemperatureValue(white_level)
      //setSliderTemperatureOverridenValue(datavalue.data.overriden_white_level)
      //setTemperaureValueInheritance("own")
      await _setWarmWhiteLevel(datavalue.data);
    }
    setLoading(false);
    _setGLoading(false);
  }


  const setBLUEONOFF = async (value) => {
    setLoading(true);
    _setGLoading(true);

    const datavalue = await updatedBLUEONOFFOverride(group.uuid, value);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      //setonmode(value);
      //setOnModeInheritance("own")
      await _setBlueOnOff(datavalue.data);
    }
    setLoading(false);
    _setGLoading(false);
  }

  const setCircadianONOFF = async (value) => {
    setLoading(true);
    _setGLoading(true);

    const datavalue = await updatedCircadianRhythmOverride(group.uuid, value);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      //setonmode(value);
      //setOnModeInheritance("own")
      await _setCircadianOnOff(datavalue.data);
    }
    setLoading(false);
    _setGLoading(false);
  }

  const onChangeTreeButton = async (value) => {
    //console.log("click")
    if (!isModalVisible) {
      setONDETECTOFF(value)
    }
  }

  const onChangeBlueButton = async (value) => {
    //console.log("click")
    if (!isModalVisible) {
      setBLUEONOFF(value)
    }
  }

  const onChangeCircadianButton = async (value) => {
    //console.log("click")
    if (!isModalVisible) {
      setCircadianONOFF(value)
    }
  }

  //change slider
  const handleChangeSliderBright = value => {
    if (brightValueInheritance === "own") {
      setSliderBrightValue(value);
    } else if (brightValueInheritance === "override") {
      setSliderBrightOverridenValue(value)
    }
  }

  const handleChangeSliderTemperature = value => {
    if (temperatureValueInheritance === "own") {
      setSliderTemperatureValue(value)
    } else if (temperatureValueInheritance === "override") {
      setSliderTemperatureOverridenValue(value)
    }
  }

  //change slider up
  const handleAfterChangeSliderBright = value => {
    changeLightLevel(value);
  };
  const handleAfterChangeSliderTemperature = value => {
    changeWarmLevel(value)
  };

  const logout = async () => {
    await _setGroup({})
    await _setEmptyChartData()
    localStorageUtil.clearAppStorage();
    history.push("/login")
  }

  const getSliderBrightMarks = () => {
    const marks = {}
    marks[brightValueInheritance === "own" ? sliderBrightOverridenValue : sliderbrightvalue] = {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "40px"
      },
      label: <span><div className="arrow_box"><span className="bubble-text">{brightValueInheritance === "own" ? sliderBrightOverridenValue : sliderbrightvalue}%</span></div></span>
    }
    if (brightValueInheritance === "own") {
      if (sliderBrightOverridenValue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>0% </span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">0%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderBrightOverridenValue}%</span></div></div></span>
        }
      }
      if (sliderBrightOverridenValue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>100%</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-right">100%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderBrightOverridenValue}%</span></div></div></span>
        }
      }
    } else {
      if (sliderbrightvalue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>0%</span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">0%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderbrightvalue}%</span></div></div></span>
        }
      }
      if (sliderbrightvalue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A"
          },
          label: <span>100%</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "40px"
          },
          label: <span><div className="inline-section"> <div className="inline-div-static-label-right">100%</div> <div className="arrow_box inline-div"><span className="bubble-text">{sliderbrightvalue}%</span></div></div></span>
        }
      }
    }
    return marks
  }

  const getSliderTemperatureMarks = () => {
    const marks = {}
    marks[temperatureValueInheritance === "own" ? sliderTemperatureOverridenValue : slidertemperaturevalue] = {
      style: {
        color: "#ff4d4f",
        fontSize: '12px',
        minWidth: "50px"
      },
      label: <div className="arrow_box">{temperatureValueInheritance === "own" ? lightColorTemperatureFormatter(sliderTemperatureOverridenValue) : lightColorTemperatureFormatter(slidertemperaturevalue)}</div>
    }
    if (temperatureValueInheritance === "own") {
      if (sliderTemperatureOverridenValue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Warm White</span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "50px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">Warm White</div> <div className="arrow_box inline-div"><span className="bubble-text">{lightColorTemperatureFormatter(sliderTemperatureOverridenValue)}</span></div></div></span>
        }
      }
      if (sliderTemperatureOverridenValue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Bright White</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "70px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-right">Bright White</div> <div className="arrow_box inline-div"><span className="bubble-text">{lightColorTemperatureFormatter(sliderTemperatureOverridenValue)}</span></div></div></span>
        }
      }
    } else {
      if (slidertemperaturevalue !== 0) {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Warm White</span>
        }
      } else {
        marks[0] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "50px"
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-left">Warm White</div> <div className="arrow_box inline-div"><span className="bubble-text">{lightColorTemperatureFormatter(slidertemperaturevalue)}</span></div></div></span>
        }
      }
      if (slidertemperaturevalue !== 100) {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: "100px"
          },
          label: <span>Bright White</span>
        }
      } else {
        marks[100] = {
          style: {
            fontSize: '12px',
            color: "#53575A",
            minWidth: '70px'
          },
          label: <span><div className="inline-section"><div className="inline-div-static-label-right">Bright White</div> <div className="arrow_box inline-div"><span className="bubble-text">{lightColorTemperatureFormatter(slidertemperaturevalue)}</span></div></div></span>
        }
      }
    }
    return marks
  }


  const getSliderMaxTimeON = () => {
    const marks = {}
    if (maxTimeON !== 1) {
      marks[0] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: "100px"
        },
        label: <span>00:01 hours</span>
      }
    } else {
      marks[0] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: "50px"
        },
        label: <span>00:01 hours</span>
      }
    }
    if (maxTimeON !== 1440) {
      marks[1440] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: "100px"
        },
        label: <span>24:00 hours</span>
      }
    } else {
      marks[1440] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: '70px'
        },
        label: <span>24:00 hours</span>
      }
    }

    return marks
  }

  const getSliderMaxTimeOFF = () => {
    const marks = {}

    if (maxTimeOFF !== 1) {
      marks[0] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: "100px"
        },
        label: <span>00:01 hours</span>
      }
    } else {
      marks[0] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: "50px"
        },
        label: <span>00:01 hours</span>
      }
    }
    if (maxTimeOFF !== 1440) {
      marks[1440] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: "100px"
        },
        label: <span>24:00 hours</span>
      }
    } else {
      marks[1440] = {
        style: {
          fontSize: '12px',
          color: "#53575A",
          minWidth: '70px'
        },
        label: <span>24:00 hours</span>
      }
    }

    return marks
  }


  const handleChangeSliderMaxTimeON = value => {
    setMaxTimeON(value * 60);
  }
  //change slider up
  const handleAfterChangeSliderMaxTimeON = value => {
    setONDETECTOFF("on", true, value * 60);
    setIsModalVisible(false);
  };

  const handleChangeSliderMaxTimeOFF = value => {
    setMaxTimeOFF(value * 60);
  }
  //change slider up
  const handleAfterChangeSliderMaxTimeOFF = value => {
    setONDETECTOFF("shutdown", true, value * 60);
    setIsModalVisible(false);
  };

  const hold = defineHold({ updateEvery: 50, holdFor: 1000 });

  const handleHold = async (value, occupancy) => {
    await setonmode(value);
    await setIsModalVisible(true);
    //console.log("touch holder");
  }

  const getFormatterTime = (value) => {
    const hours = (Math.floor(value / 60)).toFixed(0)
    console.log(value)
    const minutes = value - (hours * 60)
    const conver = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes)
    return `${conver}`;
  }

  return (!group.uuid ? (<div>
    <div className="big-text">No group selected</div>
  </div>) : (
      <>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <div className="big-text">Switch</div>

            {occupancy_sensor === true ?
              <>
                <Button className={"tree-button button-off" + (onmode === "shutdown" ? " button-active" : "") + (onModeInheritance === "override" ? " radio-button-overriden" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeTreeButton("shutdown")} disabled={loading}>OFF</Button>
                <Button className={"tree-button button-on " + (onmode === "on" ? " button-active" : "") + (onModeInheritance === "override" ? " radio-button-overriden" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeTreeButton("on")} disabled={loading}>ON</Button>
              </>
              :
              <>
                <Holdable config={hold} onHoldComplete={() => handleHold("shutdown", occupancy_sensor)}>
                  <Button className={"tree-button button-off" + (onmode === "shutdown" ? " button-active" : "") + (onModeInheritance === "override" ? " radio-button-overriden" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeTreeButton("shutdown")} disabled={loading}>OFF</Button>
                </Holdable>
                <Button className={"tree-button button-auto middle " + (onmode === "off" ? " button-active" : "") + (onModeInheritance === "override" ? " radio-button-overriden" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeTreeButton("off")} disabled={loading}>AUTO</Button>
                <Holdable config={hold} onHoldComplete={() => handleHold("on", occupancy_sensor)}>
                  <Button className={"tree-button button-on " + (onmode === "on" ? " button-active" : "") + (onModeInheritance === "override" ? " radio-button-overriden" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeTreeButton("on")} disabled={loading}>ON</Button>
                </Holdable>
              </>
            }
            <br />
            <br />
          </Col>

          <Col className="screen-pc" xs={{ span: 24 }} sm={{ span: 7 }}>
            <div className="big-text" style={{ marginBottom: "10px" }}>Blue Light Boost</div>
            <Button className={"tree-button button-off" + (bluemode === false || bluemode === null ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeBlueButton(false)} disabled={loading}>OFF</Button>
            <Button className={"tree-button button-on " + (bluemode === true ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeBlueButton(true)} disabled={loading}>ON</Button>
            {bluemode && blueTime >= 0 && <div>Blue light boost time left: {blueTime} minutes</div>}
          </Col>

          {(group.group_type === "Control" || group.group_type === "Mix") && (
            <Col className="screen-pc" xs={{ span: 24 }} sm={{ span: 7 }}>
              <div className="big-text" style={{ marginBottom: "10px" }}>Circadian Rhythm</div>
              <Button className={"tree-button button-off" + (circadianMode === false || circadianMode === null ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeCircadianButton(false)} disabled={loading}>OFF</Button>
              <Button className={"tree-button button-on " + (circadianMode === true ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeCircadianButton(true)} disabled={loading || onmode !== 'off'}>ON</Button>
              {circadianMode && circadianTime !== "" && <div>Circadian start time: {circadianTime} </div>}
            </Col>
          )}

        </Row>
        <br />

        <Row>
          <div>
            <div className="big-text">Brightness</div>
            <br />
            <div>
              <Slider disabled={loading || (circadianMode && onmode === 'off')} step={5} marks={getSliderBrightMarks()} min={0} max={100} onChange={handleChangeSliderBright} onAfterChange={handleAfterChangeSliderBright} value={brightValueInheritance === "own" ? sliderbrightvalue : sliderBrightOverridenValue} />
            </div>
          </div>


          {lightcapability !== "SINGLE" && (
            <div>
              <div className="big-text">Light Temperature</div>
              <br />
              <div>
                <Slider disabled={loading || circadianMode} marks={getSliderTemperatureMarks()} min={0} max={100} tipFormatter={lightColorTemperatureFormatter} onChange={handleChangeSliderTemperature} onAfterChange={handleAfterChangeSliderTemperature} value={temperatureValueInheritance === "own" ? slidertemperaturevalue : sliderTemperatureOverridenValue} />
              </div>
            </div>
          )}

        </Row>
        <br />
        <Row className="screen-movil" gutter={[24, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 7 }}>
            <div className="big-text" style={{ marginBottom: "10px" }}>Blue Light Boost</div>
            <Button className={"tree-button button-off" + (bluemode === false || bluemode === null ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeBlueButton(false)} disabled={loading}>OFF</Button>
            <Button className={"tree-button button-on " + (bluemode === true ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeBlueButton(true)} disabled={loading}>ON</Button>
            {bluemode && blueTime >= 0 && <div>Blue light boost time left: {blueTime} minutes</div>}
          </Col>

          {(group.group_type === "Control" || group.group_type === "Mix") && (
            <Col xs={{ span: 24 }} sm={{ span: 7 }}>
              <div className="big-text" style={{ marginBottom: "10px" }}>Circadian Rhythm</div>
              <Button className={"tree-button button-off" + (circadianMode === false || circadianMode === null ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeCircadianButton(false)} disabled={loading || onmode !== 'off'}>OFF</Button>
              <Button className={"tree-button button-on " + (circadianMode === true ? " button-active" : "") + (loading ? " button-disabled" : "")} onClick={() => onChangeCircadianButton(true)} disabled={loading || onmode !== 'off'}>ON</Button>
              {circadianMode && circadianTime !== "" && <div>Circadian start time:: {circadianTime} </div>}
            </Col>
          )}
        </Row>

        <Modal
          //title={
          //      <span>
          //        <Icon
          //          type="time"
          //        />
          //        {`MAX ${onmode === "on" ? "ON" : "OFF"}`}
          //      </span>
          //}
          className={"modal-maxtime"}
          width="60vw"
          mask={false}
          maskClosable={true}
          style={{ top: "2em" }}
          visible={isModalVisible}
          onCancel={() => { setONDETECTOFF(onmode); setIsModalVisible(false) }} //ejecutar button o retornar a estado anterior
          footer={null}
        >
          {(onmode === "on" ? (
            <div>
              <h3>Max ON time:</h3>
              <Slider disabled={loading} marks={getSliderMaxTimeON()} tipFormatter={getFormatterTime} min={1} max={1440} onChange={handleChangeSliderMaxTimeON} onAfterChange={handleAfterChangeSliderMaxTimeON} value={maxTimeON / 60} />
            </div>
          ) : (onmode === "shutdown" ? (
            <div>
              <h3>Max OFF time:</h3>
              <Slider disabled={loading} marks={getSliderMaxTimeOFF()} tipFormatter={getFormatterTime} min={1} max={1440} onChange={handleChangeSliderMaxTimeOFF} onAfterChange={handleAfterChangeSliderMaxTimeOFF} value={maxTimeOFF / 60} />
            </div>
          ) : <></>))
          }
        </Modal>

      </>
    ));
};

const mapStateToProps = state => {
  return {
    group: state.user.group,
    light_capability: state.user.light_capability

  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    _setGroup: data => {
      dispatch(setGroup(data));
    },
    _setEmptyChartData: () => {
      dispatch(setEmptyChartData());
    },
    _setOnDetectOff: data => {
      dispatch(setOnDetectOffOverride(data));
    },
    _setLightLevel: data => {
      dispatch(setLightLevelOverride(data));
    },
    _setWarmWhiteLevel: data => {
      dispatch(setWarmWhiteLevelOverride(data));
    },
    _setBlueOnOff: data => {
      dispatch(setBlueOnOffOverride(data));
    },
    _setCircadianOnOff: data => {
      dispatch(setCircadianOverride(data));
    },
    _setGLoading: data => {
      dispatch(setLoading(data));
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Control);