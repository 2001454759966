import React from "react";
import { BrowserRouter, HashRouter, Switch, Route, Redirect } from "react-router-dom";

import Login from "./containers/Login";
import ForgotPasswordForm from "./components/ForgotResetPassword/ForgotPasswordForm"
import ResetPasswordForm from "./components/ForgotResetPassword/ResetPasswordForm"
import Principal from "./containers/Principal";
import NotFound from "./components/NotFound";
import Login2faSecondStep from "./components/Login2faSecondStep" 

const Routes = () => {

  return (
      <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot_password" component={ForgotPasswordForm} />
        <Route exact path="/reset_password" component={ResetPasswordForm} />
        <Route exact path="/second_step_2fa" component={Login2faSecondStep} />
        <Route exact path="/" component={Principal} />
        <Route exact path="/404" component={NotFound} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
      </BrowserRouter>
  );
};

export default Routes;