import React, { useState, useEffect } from "react";
import { Row, Col, Slider, Input, TimePicker, Button, Tooltip, message } from "antd";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import moment from 'moment';

import localStorageUtil from "../../../utils/localStorageUtil";
import ScheduleModal from "../../../components/ScheduleModal";

import Loader from "../../../components/Loading"
import { lightColorTemperatureFormatter } from "../../../utils/lightLevelsColorTemperature"

import { createScheduleWithColorTemperature, updatedScheduleLevel, updatedScheduleColorTemperature, createdPeriodWithLevels, updatedPeriod_Levels, deletedPeriod } from "../../../api"

import { setGroup, setSchedule, setScheduleLevel, setScheduleWhiteLevel, setSchedulePeriod, addSchedulePeriod, delSchedulePeriod, setLoading, setEmptyChartData } from '../../../actions';
import "./style.css";


const Schedule = ({ group, _setGroup, _setSchedule, _setScheduleLightLevel, _setScheduleWhiteLevel, _setSchedulePeriod, _addSchedulePeriod, _delSchedulePeriod, _setGLoading, _setEmptyChartData }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState(useHistory());
  const [lightlevel, setLightlevel] = useState(0);
  const [whiteLevel, setWhiteLevel] = useState(0);
  const [timezone, setTimezone] = useState("");
  const [periods, setPeriods] = useState([]);
  const [period, setSchedulePeriod] = useState({});
  const [isScheduleModalVisible, setShowScheduleModal] = useState(false);

  useEffect(() => {
    window.cancelschedule = false;
    if (group.uuid) {
      if (group.own_schedule) {
        setPeriods(group.own_schedule.periods)
        setLightlevel(group.own_schedule.light_level);
        setWhiteLevel(group.own_schedule.white_level)
        setTimezone(group.own_schedule.timezone);
      } else {
        createSchedule()
      }
    }

    return () => {
      window.cancelschedule = true;
    };
  }, [group.uuid, group.own_schedule])

  const createSchedule = async () => {
    setLoading(true);
    _setGLoading(true);
    const datavalue = await createScheduleWithColorTemperature(group.uuid, group.name, 0, 0, 100, "Canada/Eastern");
    if (window.cancelschedule) {
      return;
    }
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _setSchedule({
        "uuid": datavalue.data.schedule_uuid,
        "id": datavalue.data.schedule_id,
        "name": group.name,
        "light_level": 0,
        "white_level": 0,
        "warm_white_level": 100,
        "timezone": "Canada/Eastern",
        "periods": [
        ]
      });
    }
    setLoading(false);
    _setGLoading(false);
  }


  const setLight_Level = async (light_level) => {
    setLoading(true);
    _setGLoading(true);
    const warmWhiteLevel = 100 - whiteLevel
    const datavalue = await updatedScheduleColorTemperature(group.uuid, group.own_schedule.uuid, light_level, whiteLevel, warmWhiteLevel);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _setScheduleLightLevel(datavalue.data.light_level);
    }
    setLoading(false);
    _setGLoading(false);
  }

  const setWhite_Level = async (white_level) => {
    setLoading(true);
    _setGLoading(true);
    const warm_white_level = 100 - white_level
    const datavalue = await updatedScheduleColorTemperature(group.uuid, group.own_schedule.uuid, lightlevel, white_level, warm_white_level);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _setScheduleWhiteLevel(datavalue.data.white_level);
    }
    setLoading(false);
    _setGLoading(false);
  }


  //change light
  const handleChangeLightLevel = value => {
    setLightlevel(value);
  }

  const handleChangeWhiteLevel = value => {
    setWhiteLevel(value);
  }

  //change light up
  const handleAfterChangeLightLevel = value => {
    setLight_Level(value);
  }

  const handleAfterChangeWhiteLevel = value => {
    setWhite_Level(value);
  }

  const showScheduleModal = (theperiod) => {
    setSchedulePeriod(theperiod || {})
    setShowScheduleModal(true)
  }

  const hideScheduleModal = () => {
    setSchedulePeriod(undefined)
    setShowScheduleModal(false)
  }

  const savePeriod = async (startH, startM, stopH, stopM, light_level, white_level) => {
    setLoading(true);
    _setGLoading(true);

    const { startT, stopT } = convert(startH, startM, stopH, stopM);
    const warm_white_level = 100 - white_level

    if (period && period.id) {
      const datavalue = await updatedPeriod_Levels(group.uuid, group.own_schedule.uuid, period.uuid, startT, stopT, light_level, white_level, warm_white_level);
      if (datavalue.error) {
        if (datavalue.autherror) {
          message.info(datavalue.message);
          logout();
        } else {
          message.error(datavalue.message);
        }
      } else {

        await _setSchedulePeriod({
          period: {
            id: datavalue.data.id,
            uuid: datavalue.data.uuid,
            start_time: datavalue.data.start_time,
            stop_time: datavalue.data.stop_time,
            light_level,
            white_level,
            warm_white_level,
          }
        });
      }
    } else {
      const datavalue = await createdPeriodWithLevels(group.uuid, group.own_schedule.uuid, startT, stopT, light_level, white_level, warm_white_level);
      if (datavalue.error) {
        if (datavalue.autherror) {
          message.info(datavalue.message);
          logout();
        } else {
          message.error(datavalue.message);
        }
      } else {
        await _addSchedulePeriod({
          len: periods.length,
          period: {
            id: datavalue.data.id,
            uuid: datavalue.data.uuid,
            start_time: datavalue.data.start_time,
            stop_time: datavalue.data.stop_time,
            light_level,
            white_level,
            warm_white_level,
          }
        });
      }
    }
    setLoading(false);
    _setGLoading(false);
    hideScheduleModal();

  }

  const convert = (startH, startM, stopH, stopM) => {
    //const offset = new Date().getTimezoneOffset() / 60;

    const utcstartH = ((Number)(startH)) % 24;
    const utcstopH = ((Number)(stopH)) % 24;

    const startT = (utcstartH + ":" + startM + ":00")
    const stopT = (utcstopH + ":" + stopM + ":00")

    return { startT, stopT };
  }

  const deletePeriod = async (period_id) => {
    setLoading(true);
    _setGLoading(true);
    const datavalue = await deletedPeriod(group.uuid, group.own_schedule.uuid, period_id);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _delSchedulePeriod({ uuid: period_id });
    }
    setLoading(false);
    _setGLoading(false);
  }

  const logout = async () => {
    await _setGroup({})
    await _setEmptyChartData()
    localStorageUtil.clearAppStorage();
    history.push("/login")
  }

  const getMoment = (date) => {
    //const getoffset = new Date().getTimezoneOffset() / 60;
    const getstartH = (Number)((date).split(':')[0]);
    const getstartM = (Number)((date).split(':')[1]);

    const getutcstart = ((Number)(getstartH)) % 24;

    const finalstartT = (getutcstart + ":" + getstartM + ":00")
    return finalstartT;
  }


  const marksbright = {
    0: {
      style: {
        fontSize: '12px'
      },
      label: <span>0%</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>100%</span>,
    }
  }

  const marksColorTemperature = {
    0: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Warm White</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Bright White</span>,
    }
  }

  return (!group.uuid ? (<div>
    <div className="big-text">No group selected</div>
  </div>) : ((group.own_schedule == null ? (<div>
    <div className="big-text"><div style={{ marginTop: 50 }}><Loader scope="navbar" typeClass="navbar" /></div></div>
  </div>) : (
      <>
        <ScheduleModal
          period={period}
          visible={isScheduleModalVisible}
          hideModal={hideScheduleModal}
          savePeriod={savePeriod}
          schedule={group.own_schedule}
          group={group}
        />
        <div>
          <div className="big-text">Brightness</div>
          <div>
            <Slider disabled={loading} step={5} marks={marksbright} min={0} max={100} onChange={handleChangeLightLevel} onAfterChange={handleAfterChangeLightLevel} value={lightlevel ? lightlevel : 0} />
          </div>
          <div className="big-text">Light Temperature</div>
          <div>
            <Slider disabled={loading} step={5} marks={marksColorTemperature} tipFormatter={lightColorTemperatureFormatter} min={0} max={100} onChange={handleChangeWhiteLevel} onAfterChange={handleAfterChangeWhiteLevel} value={whiteLevel ? whiteLevel : 0} />
          </div>
          <div className="big-text">Timezone</div>
          <div>
            <Input className="schedule-info__input" disabled value={timezone} />
          </div>
          <Row>
            {periods &&
              periods.length > 0 && periods.map((period, index) => (
                <Col xs={24} key={period.id} className="schedule-form">
                  <Row display="flex" justify="start" className="schedule-form__times">
                    <Col xs={8} sm={5} className="time">
                      <span className="time__title">ON</span>
                      <TimePicker
                        use12Hours
                        format="hh:mm a"
                        disabled={true}
                        value={moment(getMoment(period.start_time), "HH:mm:ss")}
                      />
                    </Col>
                    <Col xs={8} sm={5} className="time">
                      <span className="time__title">OFF</span>
                      <TimePicker
                        use12Hours
                        format="hh:mm a"
                        disabled={true}
                        value={moment(getMoment(period.stop_time), "HH:mm:ss")}
                      />
                    </Col>
                    <Col xs={0} sm={3}>
                      <span><b>Bright: </b>{period.light_level}%</span>
                    </Col>
                    <Col xs={0} sm={3}>
                      <span><b>Light Temperature: </b>{lightColorTemperatureFormatter(period.white_level)}</span>
                    </Col>
                    <Col xs={8} sm={3}>
                      <Row display="flex">
                        <Tooltip title="Edit Schedule">
                          <Button
                            type="info"
                            className="info"
                            shape="circle"
                            icon="edit"
                            style={{ marginLeft: 20 }}
                            loading={loading}
                            onClick={() => showScheduleModal(period)}

                          />
                        </Tooltip>
                        <Tooltip title="Delete Schedule">
                          <Button
                            type="danger"
                            className="delete"
                            shape="circle"
                            icon="delete"
                            style={{ marginLeft: 20 }}
                            loading={loading}
                            onClick={() => deletePeriod(period.uuid)}
                          />
                        </Tooltip>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))
            }
            <Col sm={{ span: 24 }} style={{ paddingTop: 20 }}>
              <Tooltip title="Add Schedule">
                <Button
                  icon="plus"
                  type="primary"
                  ghost
                  className="add-schedule-btn"
                  loading={loading}
                  onClick={() => showScheduleModal()}
                >
                  Schedule
              </Button>
              </Tooltip>
            </Col>
          </Row>
        </div>
      </>
    ))));
};

const mapStateToProps = state => {
  return {
    group: state.user.group
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    _setGroup: data => {
      dispatch(setGroup(data));
    },

    _setEmptyChartData: () => {
      dispatch(setEmptyChartData());
    },

    _setSchedule: data => {
      dispatch(setSchedule(data));
    },

    _setScheduleLightLevel: data => {
      dispatch(setScheduleLevel(data));
    },

    _setScheduleWhiteLevel: data => {
      dispatch(setScheduleWhiteLevel(data));
    },

    _setSchedulePeriod: data => {
      dispatch(setSchedulePeriod(data));
    },
    _addSchedulePeriod: data => {
      dispatch(addSchedulePeriod(data));
    },
    _delSchedulePeriod: data => {
      dispatch(delSchedulePeriod(data));
    },

    _setGLoading: data => {
      dispatch(setLoading(data));
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule);