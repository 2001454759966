import React from "react";
import { Row } from "antd";
import "./style.css";

const CenteredRow = ({
  children,
  direction = "column",
  justify = "center",
  align = "middle",
  typeClass = ""
}) => {
  return (
    <Row
      type="flex"
      justify={justify}
      align={align}
      className={typeClass}
    >
      {children}
    </Row>
  );
};

export default CenteredRow;
