import React, { useEffect, useState } from "react"
import localStorageUtil from "../../utils/localStorageUtil"
import { Button, message, Collapse, Icon, Empty, Table, Popconfirm, Tooltip, Divider, Modal } from "antd"
import { connect } from 'react-redux'
import { loadUserSavedConfigurationsList, deleteUserSavedConfiguration, activatedUserSavedConfiguration } from '../../api'
import { setUserSavedConfigurations, deleteUserSavedConfigurationAction, activatedUserSavedConfigurationAction, setGroup } from "../../actions"
import colors from "../../styles"
import CenteredRow from "../CenteredRow"
import UserSavedConfigurationForm from "../UserSavedConfigurationForm"
import StyledIcon from "../StyledIcon"
//import "./styles.css"

const { Panel } = Collapse
const { danger, success, primary_color } = colors

const UserSavedConfigurationsList = ({
  userSavedConfigurationsData
  , setIsModalVisible
  , isModalVisible
  , _setUserSavedConfigurations
  , _deleteUserSavedConfiguration
  , _activatedUserSavedConfigurationAction
  , _setGroup
  , group
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [modalType, setModalType] = useState("add")
  const [currentConfig, setCurrentConfig] = useState({})
  const [isVisibleModalConfigurationForm, setIsVisibleModalConfigurationForm] = useState(false)

  const handleDelete = async (config_uuid) => {
    const uuid = localStorageUtil.get('user_uuid')
    const datavalue = await deleteUserSavedConfiguration(uuid, config_uuid)

    if (datavalue.error) {
      message.error(datavalue.message);
    } else {
      _deleteUserSavedConfiguration(config_uuid)
      message.success("Successfully deleted user's configuration")
    }
  }

  const handleSetConfiguration = async (config_uuid) => {
    const uuid = localStorageUtil.get('user_uuid')
    const datavalue = await activatedUserSavedConfiguration(uuid, { config_uuid })

    if (datavalue.error) {
      message.error(datavalue.message);
    } else {
      _activatedUserSavedConfigurationAction(datavalue.data)
      if (Object.keys(group).length > 0 && group.uuid === datavalue.data.uuid) {
        _setGroup(datavalue.data)
      }
      message.success("Successfully activated user's configuration")
    }
  }

  const configuration_parameter = (record) => {
    return (
      <>
        <b>Configuration values:</b>
        <br/>
        <ul>
          {record && record.on_mode !== null && (
            <li>On mode: {record.on_mode}</li>
          )}
          {record && record.slider_level !== null && (
            <li>Brightness: {record.slider_level}</li>
          )}
          {record && record.white_level !== null  && (
            <li>Light Temperature: {(3000 + 30*record.white_level).toFixed(0)}K</li>
          )}
          {record && record.blue_light_boost !== null && (
            <li>Blue light boost: {record.blue_light_boost ? "On": "Off"}</li>
          )}
          {record && record.circadian_rhythm !== null && (
            <li>Circadian rhythm: {record.circadian_rhythm ? "On": "Off"}</li>
          )}
        </ul>
      </>
    )
  }

  const columns = [
    {
      title: "Configuration Name",
      dataIndex: "name",
      key: "uuid",
      width: "60%",
      render: (text, record) => (
        <Tooltip placement="topRight" title={configuration_parameter(record)}>{text}</Tooltip>
      )
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>

          <Tooltip placement="topRight" title="Edit">
            <span>
              <StyledIcon
                handleClick={() => {
                  setModalType("update")
                  setCurrentConfig(record)
                  setIsVisibleModalConfigurationForm(true)
                }}
                type="edit"
                scope="action"
                color={success}
              />
            </span>
          </Tooltip>
          <Divider type="vertical" style={{ margin: "0 1em" }} />

          <Popconfirm
            title={`You are about to set the configuration ${record.name} for the group. Are you sure?`}
            onConfirm={() => handleSetConfiguration(record.uuid)}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
          >
            <Tooltip placement="topRight" title="Set configuration">
              <span>
                <StyledIcon type="select" scope="action" color={primary_color} />
              </span>
            </Tooltip>
          </Popconfirm>
          <Divider type="vertical" style={{ margin: "0 1em" }} />

          <Popconfirm
            title={`You are about to delete the configuration ${record.name}. Are you sure?`}
            onConfirm={() => handleDelete(record.uuid)}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
          >
            <Tooltip placement="topRight" title="Delete">
              <span>
                <StyledIcon type="delete" scope="action" color={danger} />
              </span>
            </Tooltip>
          </Popconfirm>

        </>
      ),
      width: "20%",
      align: "center"
    }
  ]

  useEffect(
    () => {
      (async () => {
        const uuid = localStorageUtil.get('user_uuid')
        const datavalue = await loadUserSavedConfigurationsList(uuid)

        if (datavalue.error) {
          message.error(datavalue.message);
        } else {
          const data = datavalue.data
          _setUserSavedConfigurations(data)
        }
        setIsLoading(false)
      })()
    },
    []
  )

  const handleCancel = (e) => {
    setIsModalVisible(false)
  }

  const handleAddConfiguration = (e) => {
    setModalType("add")
    setIsVisibleModalConfigurationForm(true)
  }

  return (isLoading ? (
    <CenteredRow typeClass="screen" >
      <span className={"icon-animated"}>
        <Icon
          type="loading"
          style={{ color: "#287d8d", fontSize: "50px" }}
        />
      </span>
    </CenteredRow>
  ) : (
      <>
        {userSavedConfigurationsData && userSavedConfigurationsData.length > 0 ?
          <Collapse defaultActiveKey={userSavedConfigurationsData[0].group_uuid}>
            {userSavedConfigurationsData.map(group_configurations => {
              return (
                <Panel header={<b>{group_configurations.group_name}</b>} key={group_configurations.group_uuid}>
                  <Table
                    bordered
                    style={{ width: "100%" }}
                    size="medium"
                    columns={columns}
                    dataSource={group_configurations.configurations.map(configuration => {
                      configuration.key = configuration.uuid;
                      return configuration
                    })}
                  />
                </Panel>

              )
            })}
          </Collapse>
          :
          <Empty />
        }
        <br />
        { Object.keys(group).length > 0 && (
          <>
            <Button
              className="screen-pc"
              type="primary"
              htmlType="submit"
              style={{ width: "40%" }}
              size="large"
              icon={"upload"}
              loading={isLoading}
              onClick={handleAddConfiguration}
            >
              {" Save configuration for current group"}
            </Button>
            <Button
              className="screen-movil"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              size="large"
              icon={"upload"}
              loading={isLoading}
              onClick={handleAddConfiguration}
            >
              {" Save configuration for current group"}
            </Button>
            <br className="screen-movil" />
          </>
        )}

        <Button
          type="default"
          htmlType="button"
          onClick={handleCancel}
          icon={"close"}
          style={{ width: "40%", marginLeft: "1em" }}
          size="large"
        >
          Cancel
          </Button>

        <Modal
          title={
            <span>
              {modalType === "add" ? "Add new configuration" : "Edit configuration"}
            </span>
          }
          width="100%"
          maskClosable={true}
          destroyOnClose={true}
          style={{ top: "2em" }}
          visible={isVisibleModalConfigurationForm}
          onCancel={() => setIsVisibleModalConfigurationForm(false)}
          footer={null}
        >

          <UserSavedConfigurationForm
            setIsModalVisible={setIsVisibleModalConfigurationForm}
            isModalVisible={isVisibleModalConfigurationForm}
            configuration={currentConfig}
            type={modalType}
            configurationValues={configuration_parameter}
          />
        </Modal>
      </>

    )
  )
};

const mapStateToProps = state => {
  return {
    userSavedConfigurationsData: state.userSavedConfigurations.userSavedConfigurationsData,
    group: state.user.group
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _setUserSavedConfigurations: data => {
      dispatch(setUserSavedConfigurations(data));
    },
    _deleteUserSavedConfiguration: config_uuid => {
      dispatch(deleteUserSavedConfigurationAction(config_uuid));
    },
    _activatedUserSavedConfigurationAction: data => {
      dispatch(activatedUserSavedConfigurationAction(data));
    },
    _setGroup: data => {
      dispatch(setGroup(data));
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSavedConfigurationsList);