import React, { useState } from "react";
import { connect } from 'react-redux';
import { Row, Col, Card, Form, Icon, Input, Button, message } from 'antd';
import { useHistory, Link } from "react-router-dom";

import { resetUserPassword } from '../../../actions';

import { passwordReset } from '../../../api'
import "./style.css";

const TextArea = Input.TextArea

const ResetPasswordForm = ({ form, resetUserPassword }) => {
  const [loading, setLoading] = useState(false);
  const [history,] = useState(useHistory());

  //const customData = require('../../config.json')
  const customData = window.Configs;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      setLoading(true)
      if (!err) {
        const { reset_token, password, password2 } = values;

        const datavalue = await passwordReset(reset_token, password, password2)

        if (datavalue.error) {
          message.error(datavalue.message);
          setLoading(false);
        } else {
          const data = datavalue.data
          resetUserPassword({ data })

          resetFields();

          message.success("Password changed successfully")
          setLoading(false);
          history.push('/login')
        }

      } else {
        message.error(err);
        setLoading(false);
      }
    })
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value) {
      form.validateFields(["confirm"], { force: true })
    }
    callback();
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Password confirm is inconsistent");
    } else {
      callback();
    }
  }

  const { getFieldDecorator, validateFields, resetFields } = form;

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", backgroundColor: "#cbdce9" }}
    >
      <Col xs={20} sm={14} md={12} lg={10} xl={7}>
        <Card>
          <h3>Check your email for the token to reset your password, if you not have received email, request a new one</h3>
          <Form onSubmit={handleSubmit} className="login-form" style={{margin:"auto"}}>
            <Form.Item>
              {getFieldDecorator("reset_token", { 
                rules: [
                  { required: true, message: 'Please input the reset token!' }
              ]}
              )(
                <TextArea placeholder="Input reset token from email" disabled={loading} />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your new password!' },
                  {
                    validator: validateToNextPassword
                  }
                ]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="New password"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password2', {
                rules: [
                  { required: true, message: 'Please input your password confirmation!' },
                  {
                    validator: compareToFirstPassword
                  }
                ]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password confirmation"
                />,
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ backgroundColor: '#287d8e', width: "100%" }}
                loading={loading}
                onClick={() => handleSubmit}>
                Change your password
            </Button>

            </Form.Item>
          </Form>
          <div>You did not recieved a token?, then request one <span> </span>
              <Link to="forgot_password">
                <a
                  type="link"
                  disabled={loading}
                >
                  here
              </a>
              </Link>
              !
            </div>
            <Row
              type="flex"
              justify="center"
              align="middle">
              <Link to="/login">
                <Button
                  type="default"
                  style={{ width: "100%", marginTop: "2em" }}
                  size="large"
                  loading={loading}
                >
                  Cancel
          </Button>
              </Link>
            </Row>
        </Card>
      </Col>
    </Row>
  );

}

const WrappedResetPasswordForm = Form.create({ name: 'reset_password_form' })(ResetPasswordForm);

const mapDispatchToProps = (dispatch) => {
  return {
    resetUserPassword: form => {
      dispatch(resetUserPassword(form));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps
)(WrappedResetPasswordForm);
