import moment from 'moment'
import { loadChartSensorDataPower, loadChartSensorDataMotion } from "../api";

export const proccessPromises = async (promises) => {
    let data = await Promise.all(promises)

    return data
}

export const makeParallelRequest = (sensor, interval, max_interval_size, paramcurrentTime, group_uuid) => {
    let currentTime = paramcurrentTime
    let count = 1
    let tmpstart = moment.utc().subtract(max_interval_size - 1, 'hours').startOf('hours')
    let tmpend = moment.utc().subtract(max_interval_size - 1, 'hours').startOf('hours')
    let tmpcurrentTime = tmpend.add(interval, 'hours').subtract(1, 'seconds')
    const promises = []

    while (tmpstart <= currentTime || parseFloat(tmpstart.format('mm')) >= 60*interval) {
        if (count <= (max_interval_size)/(0.5) ) {
            
            promises.push(new Promise(resolve => {
                const datavalue = loadChartSensorDataPower(
                    group_uuid,
                    sensor,
                    tmpstart.format('YYYYMMDDHHmmss'),
                    tmpcurrentTime.format('YYYYMMDDHHmmss'),
                )
                resolve(datavalue)
            }
            ))

        } 

        count++
        tmpstart = tmpstart.add(interval, 'hours')
        tmpend = tmpend.add(interval, 'hours')
        tmpcurrentTime = tmpend

        //console.log(tmpstart.format('YYYYMMDDHHmmss') + " <= " + currentTime.format('YYYYMMDDHHmmss'))
    }

    return promises
}

export const unifyRequestsResults = fetch_data => {
    const result = { "auth_error": false }
    let datavalue = {}
    let emptydata = true
    let countdataempty = 0
    let current_data;

    for (let i = 0; i < fetch_data.length; i++) {
        if (fetch_data[i].error) {
            if (fetch_data[i].autherror) {
                //message.info(fetch_data[i].message);
                //logout();
                result.auth_error = true
                datavalue = {}
                result.message = fetch_data[i].message
                break
            } else {
                //message.error(fetch_data[i].message)
                datavalue = {}
                result.message = fetch_data[i].message
                break
            }
        } else {
            if (fetch_data[i].data && Object.keys(fetch_data[i].data).length > 0) {
                if (emptydata) {
                    datavalue = fetch_data[i].data['data'];

                    emptydata = false;
                } else {

                    current_data = { ...fetch_data[i].data['data'] }

                    for (const year in current_data) {
                        if (current_data.hasOwnProperty(year)) {
                            for (const mounth in current_data[year]) {
                                if (current_data[year].hasOwnProperty(mounth)) {
                                    for (const day in current_data[year][mounth]) {
                                        if (current_data[year][mounth].hasOwnProperty(day)) {
                                            for (const hour in current_data[year][mounth][day]) {
                                                if (current_data[year][mounth][day].hasOwnProperty(hour)) {
                                                    //console.log(datavalue)

                                                    if (!datavalue.hasOwnProperty(year)) {
                                                        datavalue = {
                                                            ...datavalue,
                                                            [year]: {
                                                                [mounth]: {
                                                                    [day]: {

                                                                        [hour]: {
                                                                            ['data']: current_data[year][mounth][day][hour]['data'],
                                                                            ['count']: current_data[year][mounth][day][hour]['count']
                                                                        }

                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    if (!datavalue[year].hasOwnProperty(mounth)) {
                                                        datavalue = {
                                                            ...datavalue,
                                                            [year]: {
                                                                ...datavalue[year],
                                                                [mounth]: {
                                                                    [day]: {
                                                                        [hour]: {
                                                                            ['data']: current_data[year][mounth][day][hour]['data'],
                                                                            ['count']: current_data[year][mounth][day][hour]['count']
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    if (!datavalue[year][mounth].hasOwnProperty(day)) {
                                                        datavalue = {
                                                            ...datavalue,
                                                            [year]: {
                                                                ...datavalue[year],
                                                                [mounth]: {
                                                                    ...datavalue[year][mounth],
                                                                    [day]: {
                                                                        [hour]: {
                                                                            ['data']: current_data[year][mounth][day][hour]['data'],
                                                                            ['count']: current_data[year][mounth][day][hour]['count']
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }

                                                    if (!datavalue[year][mounth][day].hasOwnProperty(hour)) {
                                                        datavalue = {
                                                            ...datavalue,
                                                            [year]: {
                                                                ...datavalue[year],
                                                                [mounth]: {
                                                                    ...datavalue[year][mounth],
                                                                    [day]: {
                                                                        ...datavalue[year][mounth][day],
                                                                        [hour]: {
                                                                            ['data']: current_data[year][mounth][day][hour]['data'],
                                                                            ['count']: current_data[year][mounth][day][hour]['count']
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    } else {

                                                        datavalue = {
                                                            ...datavalue,
                                                            [year]: {
                                                                ...datavalue[year],
                                                                [mounth]: {
                                                                    ...datavalue[year][mounth],
                                                                    [day]: {
                                                                        ...datavalue[year][mounth][day],
                                                                        [hour]: {
                                                                            ['data']: datavalue[year][mounth][day][hour]['data'] + current_data[year][mounth][day][hour]['data'],
                                                                            ['count']: datavalue[year][mounth][day][hour]['count'] + current_data[year][mounth][day][hour]['count']
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    //datavalue[year][mounth][day][hour] = current_data[year][mounth][day][hour]
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    //datavalue = current_data;
                }
            } else {
                countdataempty = countdataempty + 1;
            }
        }
    }

    //console.log(datavalue)
    result.datavalue = datavalue
    return result
}
