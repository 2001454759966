import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Empty, List, Alert, Icon } from 'antd'
import { withRouter } from "react-router-dom";
//import Loader from "../../components/Loader";
import NotificationItem from "../NotificationItem";
//import { UserContext } from "../../context";
//import * as actions from '../../constants';
import './styles.css';
import { getMessageIconType, getMessageType } from "../../../utils/helpers";


const NotificationList = ({
    history,
    transport,
    notificationData,
    isLoading,
    selectNotification
}) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(isLoading)
    },
        [isLoading]
    )

    const notificationDetail = (notification_transport, index) => {
        if (notification_transport === 1) {
            selectNotification(index)
        }
    }

    const getNotificationUserState = (notification) => {
        if (notification.transport === 1) {
            if (notification.notification_receipts && notification.notification_receipts.length > 0) {
                if (notification.notification_receipts[0].is_read) {
                    return 1 //Read
                } else {
                    return 2 //UNREAD
                }
            } else {
                return 2//UNREAD
            }
        } else {
            return notification.notification_state
        }
    }

    return (
        loading //isLoading
            ? <></>
            :
            <>
                <div className="toast-data">
                    {notificationData && notificationData.length > 0 ?
                        <List
                            className="toasts-message"
                            itemLayout="horizontal"
                            dataSource={notificationData}
                            renderItem={(item, index) => (
                                <List.Item onClick={() => notificationDetail(item.transport, index)}>
                                    {item.message_type === 4 ?

                                        <Alert
                                            key={`toast-${index}`}
                                            description={
                                                <NotificationItem
                                                    message_type={getMessageType(item.message_type)}
                                                    message={item.transport === 2 ? item.subject : item.message}
                                                    date={item.send_date}
                                                    notification_state={getNotificationUserState(item)}
                                                />
                                            }
                                            type={getMessageIconType(item.message_type)}
                                            showIcon
                                            icon={<Icon type="bell" />}
                                        /> :

                                        <Alert
                                            key={`toast-${index}`}
                                            //message={getMessageType(item.message_type)}
                                            description={
                                                <NotificationItem
                                                    message_type={getMessageType(item.message_type)}
                                                    message={item.transport === 2 ? item.subject : item.message}
                                                    date={item.send_date}
                                                    notification_state={getNotificationUserState(item)}
                                                />
                                            }
                                            type={getMessageIconType(item.message_type)}
                                            showIcon
                                        />
                                    }

                                </List.Item>
                            )}
                        />
                        : <Empty />}

                </div>
            </>
    )
}

export default withRouter(NotificationList)