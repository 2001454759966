import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col, Card, Form, Icon, Input, Button, message } from 'antd';
import { useHistory } from "react-router-dom";

import localStorageUtil from "../../utils/localStorageUtil";

import { loginUser, login2faFirstStep, getCompanies } from '../../actions'
import { setAuthToken } from '../../services'

import { login2fa } from '../../api'
import "./style.css";


const LoginForm = ({ form, loginUser, getCompanies }) => {
  const [loading, setLoading] = useState(false);
  const [history,] = useState(useHistory());

  //const customData = require('../../config.json')
  const customData = window.Configs;
  const APP_ENVIROMENT = customData.APP_ENVIROMENT

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      setLoading(true)
      if (!err) {
        const { username, password } = values;

        const datavalue = await login2fa(username, password)

        if (datavalue.error) {
          message.error(datavalue.message);
          setLoading(false);
        } else {
          const data = datavalue.data
          if (!data.active_2fa){
            loginUser({ username: data.username, access_token: data.access_token, refresh_token: data.refresh_token, modules: data.modules, uuid: data.uuid });
            // save in local storage
            localStorageUtil.setToken(data.access_token);
            localStorageUtil.setRefreshToken(data.refresh_token)
            localStorageUtil.set(data.username + "", 'username')
            localStorageUtil.set(data.uuid + "", 'user_uuid')

            // update headers of ajax request
            setAuthToken(data.access_token);
            resetFields();

            await getCompanies(data.companies);

            setLoading(false)
            history.push('/')
          } else {
            login2faFirstStep({username: data.username, access_token: data.access_token, refresh_token: data.refresh_token, uuid: data.uuid})
            localStorageUtil.setToken(data.access_token)
            setAuthToken(data.access_token)
            localStorageUtil.set(data.username + "", 'username')
            localStorageUtil.set(data.uuid + "", 'user_uuid')
            setLoading(false)
            history.push('/second_step_2fa')
          }
        }

      } else {
        message.error(err);
        setLoading(false);
      }
    });
  };

  const { getFieldDecorator, validateFields, resetFields } = form;
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", backgroundColor: "#cbdce9" }}
    >
      <Col xs={20} sm={14} md={12} lg={10} xl={7}>
        <Card>
          <img className="login-logo" src={process.env.PUBLIC_URL + '/logo80.png'} alt="ILLUMENAI" />
          <h3 className="centered-subtitle">User App {APP_ENVIROMENT === "dev" && (<span>(<Icon type="bug" /> DEV)</span>)}</h3>
          <Form onSubmit={handleSubmit} className="login-form"  style={{margin:"auto"}}>
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />,
              )}
            </Form.Item>
            <Link to="/forgot_password">
                <Button
                  type="link"
                  style={{ width: "100%", marginBottom: "5%" }}
                  disabled={loading}
                >
                  I forgot my password
              </Button>
            </Link>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ backgroundColor: '#287d8e', width: "100%" }}
                loading={loading}
                onClick={() => handleSubmit}>
                Log in
          </Button>

            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );

}

const WrappedNormalLogin = Form.create({ name: 'normal_login' })(LoginForm);

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: form => {
      dispatch(loginUser(form));
    },
    getCompanies: companies => {
      dispatch(getCompanies(companies));
    },
    login2faFirstStep: form => {
      dispatch(getCompanies(form))
    }
    
  };
};
export default connect(
  null,
  mapDispatchToProps
)(WrappedNormalLogin)
