
const initialState = {
  user: {
    username: '',
    access_token: '',
    refresh_token: '',
    modules: [],
  },
  sensordata: [],
  chartdata: {},
  chartloading: {},
  loading: false,
  currenttab: '1',
  sensortype: 'temperature',
  light_capability: {},
  group: {

  },

  notificationData: {
    "all": [],
    "toast": [],
    "mail": [],
    "sms": []
  },
  notificationSendData: {
    "all": [],
    "toast": [],
    "mail": [],
    "sms": []
  },

  notificationToast: [],
  notificationUpdateDetail: false,
}

const tabs = {
  "Week": 0,
  "Weekdays": 1,
  "Weekend": 2,
  "Monday": 3,
  "Tuesday": 4,
  "Wednesday": 5,
  "Thursday": 6,
  "Friday": 7,
  "Saturday": 8,
  "Sunday": 9
}


const user = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN':
      return { ...state, user: action.payload };

    case 'GET_COMPANIES':
      return { ...state, companies: action.payload };

    case 'SET_GROUP':
      return { ...state, group: action.payload };

    case 'SET_LIGHTCAPABILITY':
      return { ...state, light_capability: action.payload.light_capability };


    case 'SET_LOADING':
      return { ...state, loading: action.payload };

    case 'SET_TAB':
      return { ...state, currenttab: action.payload };

    case 'SET_SENSORTYPE':
      return { ...state, sensortype: action.payload };


    //TAB CONTROL
    //UPDATED CONTROL
    case 'SET_ONDETECTOFF_OVERRIDE':
      return { ...state, group: { ...state.group, on_mode: action.payload.on_mode, on_inheritance: action.payload.on_inheritance, occupancy_sensor: action.payload.occupancy_sensor, max_on_secs: action.payload.max_on_secs, max_off_secs: action.payload.max_off_secs } };

    case 'SET_LIGHTLEVEL_OVERRIDE':
      return { ...state, group: { ...state.group, light_level: action.payload.light_level, slider_level: action.payload.slider_level, overriden_slider_level: action.payload.overriden_slider_level, slider_level_inheritance: action.payload.slider_level_inheritance } };

    case 'SET_WARMWHITELEVEL_OVERRIDE':
      return { ...state, group: { ...state.group, white_level: action.payload.white_level, warm_white_level: action.payload.warm_white_level, overriden_white_level: action.payload.overriden_white_level, color_level_inheritance: action.payload.color_level_inheritance } };

    //TAB SCHEDULE
    case 'SET_SCHEDULE':
      return { ...state, group: { ...state.group, own_schedule: action.payload } };

    case 'SET_SCHEDULELEVEL':
      return { ...state, group: { ...state.group, own_schedule: { ...state.group.own_schedule, light_level: action.payload } } };

    case 'SET_SCHEDULE_WHITE_LEVEL':
      return { ...state, group: { ...state.group, own_schedule: { ...state.group.own_schedule, white_level: action.payload, warm_white_level: (100 - action.payload) } } };

    case 'SET_SCHEDULEALLPERIOD':
      return { ...state, group: { ...state.group, own_schedule: { ...state.group.own_schedule, periods: action.payload } } };


    case 'SET_SCHEDULEPERIOD':
      return {
        ...state, group: {
          ...state.group, own_schedule: {
            ...state.group.own_schedule, periods: [
              ...state.group.own_schedule.periods.map((item) => {
                if (item.uuid !== action.payload.period.uuid) {
                  return item;
                }
                return action.payload.period;
              })
            ]
          }
        }
      };

    case 'GET_SCHEDULEPERIOD':
      return { ...state, group: { ...state.group, own_schedule: { ...state.group.own_schedule, periods: action.payload } } };

    case 'ADD_SCHEDULEPERIOD':
      return {
        ...state, group: {
          ...state.group, own_schedule: {
            ...state.group.own_schedule, periods: [
              ...state.group.own_schedule.periods.slice(0, action.payload.len),
              action.payload.period
            ]
          }
        }
      };

    case 'DEL_SCHEDULEPERIOD':
      return {
        ...state, group: {
          ...state.group, own_schedule: {
            ...state.group.own_schedule, periods: [
              ...state.group.own_schedule.periods.filter((item, index) => item.uuid !== action.payload.uuid)
            ]
          }
        }
      };


    //TAB DATA
    case 'SET_SENSORDATA':
      return { ...state, sensordata: action.payload };

    //CHART
    case 'SET_CHARTLOADINGDATA':
      const sensor2 = action.payload.sensor
      return { ...state, chartloading: { ...state.chartloading, [sensor2]: action.payload.data } };

    //EMPTY CHART
    case 'SET_CHARTDATA':
      return { ...state, chartdata: {} };

    //CHART
    case 'SET_CHARTSENSORDATA':
      const sensor3 = action.payload.sensor
      return { ...state, chartdata: { ...state.chartdata, [sensor3]: action.payload.data } };



    //TAB ADVANCED SCHEDULE
    case 'SET_ADVANCED_SCHEDULE_ENABLED':
      return { ...state, group: { ...state.group, advanced_schedule_enabled: action.payload.advanced_schedule_enabled } };



    case 'SET_ADVANCED_SCHEDULE':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: action.payload.sort(function (a, b) {
            return tabs[a.schedule_type] - tabs[b.schedule_type];
          })
        }
      };



    case 'ADD_ADVANCED_SCHEDULE':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: ([
            ...state.group.advanced_schedule.slice(0, action.payload.len),
            action.payload.advanced_schedule
          ]).sort(function (a, b) {
            return tabs[a.schedule_type] - tabs[b.schedule_type];
          })
        }
      };

    case 'DEL_ADVANCED_SCHEDULE':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: [
            ...state.group.advanced_schedule.filter((item, index) => item.uuid !== action.payload.uuid)
          ]
        }
      };




    case 'SET_ADVANCED_SCHEDULE_LEVEL':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: [
            ...state.group.advanced_schedule.map((item) => {
              if (item.uuid !== action.payload.uuid) {
                return item;
              } else {
                let change_item = item
                change_item.light_level = action.payload.light_level
                return change_item
              }

            })
          ]

        }
      };

    //case 'SET_ADVANCED_SCHEDULE_WHITE_LEVEL':
    //  return { ...state, group: { ...state.group, advanced_schedule: { ...state.group.advanced_schedule, white_level: action.payload, warm_white_level: (100 - action.payload) } } };

    case 'SET_ADVANCED_SCHEDULE_WHITE_LEVEL':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: [
            ...state.group.advanced_schedule.map((item) => {
              if (item.uuid !== action.payload.uuid) {
                return item;
              } else {
                let change_item = item
                change_item.white_level = action.payload.white_level
                change_item.warm_white_level = (100 - action.payload.white_level)
                return change_item
              }

            })
          ]
        }
      };


    case 'SET__ADVANCED_SCHEDULE_ALL_PERIOD':
      return { ...state, group: { ...state.group, advanced_schedule: { ...state.group.advanced_schedule, periods: action.payload } } };


    case 'SET_ADVANCED_SCHEDULE_PERIOD':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: [
            ...state.group.advanced_schedule.map((item) => {
              if (item.uuid !== action.payload.schedule_uuid) {
                return item;
              } else {
                let change_item = item

                change_item.advanced_schedule_periods =
                  item.advanced_schedule_periods.map((item) => {
                    if (item.uuid !== action.payload.advanced_schedule_period.uuid) {
                      return item;
                    }
                    return action.payload.advanced_schedule_period;
                  })

                return change_item
              }

            })
          ]
        }
      };

    case 'GET_ADVANCED_SCHEDULE_PERIOD':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: [
            ...state.group.advanced_schedule.map((item) => {
              if (item.uuid !== action.payload.schedule_uuid) {
                return item;
              } else {
                let change_item = item
                change_item.advanced_schedule_periods = action.payload.advanced_schedule_periods
                return change_item
              }

            })
          ]
        }
      };

    case 'ADD_ADVANCED_SCHEDULE_PERIOD':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: [
            ...state.group.advanced_schedule.map((item) => {
              if (item.uuid !== action.payload.schedule_uuid) {
                return item;
              } else {
                let change_item = item
                change_item.advanced_schedule_periods = item.advanced_schedule_periods.concat([action.payload.advanced_schedule_period])
                return change_item
              }

            })
          ]
        }
      };

    case 'DEL_ADVANCED_SCHEDULE_PERIOD':
      return {
        ...state, group: {
          ...state.group, advanced_schedule: [
            ...state.group.advanced_schedule.map((item) => {
              if (item.uuid !== action.payload.schedule_uuid) {
                return item;
              } else {
                let change_item = item
                change_item.advanced_schedule_periods = item.advanced_schedule_periods.filter((item, index) => item.uuid !== action.payload.period_uuid)
                return change_item
              }

            })
          ]
        }
      };



    case 'SET_USER_NOTIFICATION':
      switch (action.payload.transport) {
        case "all":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "all": action.payload.notification },
            error: null
          })
        case "toast":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "toast": action.payload.notification },
            error: null
          })
        case "mail":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "mail": action.payload.notification },
            error: null
          })
        case "sms":
          return Object.assign({}, state, {
            notificationData: { ...state.notificationData, "sms": action.payload.notification },
            error: null
          })
        default:
          return state
      }


    case 'SET_NOTIFICATION_RECEIPT':
      switch (action.payload.transport) {
        case "all":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "all": state.notificationData.all.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null
          })
        case "toast":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "toast": state.notificationData.toast.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null
          })
        case "mail":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "mail": state.notificationData.mail.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null
          })
        case "sms":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "sms": state.notificationData.sms.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null
          })
        default:
          return state
      }

    case 'SET_NOTIFICATION_RECEIPT_CONFIRMED':
      switch (action.payload.transport) {
        case "all":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "all": state.notificationData.all.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null,
            notificationUpdateDetail: true
          }
          )
        case "toast":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "toast": state.notificationData.toast.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null,
            notificationUpdateDetail: true,
          })
        case "mail":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "mail": state.notificationData.mail.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null,
            notificationUpdateDetail: true
          })
        case "sms":
          return Object.assign({}, state, {
            notificationData: {
              ...state.notificationData, "sms": state.notificationData.sms.map(function (item) {
                if (item.uuid === action.payload.notification_uuid) {
                  return { ...item, "notification_receipts": action.payload.notification_receipt }
                } else {
                  return item
                }
              })
            },
            error: null,
            notificationUpdateDetail: true
          })
        default:
          return state
      }


    case 'SET_TOAST_NOTIFICATION':
      let hash = {};
      return Object.assign({}, state, {
        notificationToast: (state.notificationToast.concat(action.payload.notification)).filter(function (current) {
          var exists = !hash[current.uuid];
          hash[current.uuid] = true;
          return exists;
        })
      })


    case 'ADD_NEW_NOTIFICATIONS':
      let hashall = {};
      let hashtoast = {};
      let hashmail = {};
      let hashsms = {};
      return Object.assign({}, state, {
        notificationData: {
          ...state.notificationData,

          "all": ((action.payload.notification).filter(function (current) {
            var exists = !hashall[current.uuid];
            hashall[current.uuid] = true;
            return exists;
          })).concat(state.notificationData.all),
          "toast": ((action.payload.notification.filter((item) => { return item.transport === 1 })).filter(function (current) {
            var exists = !hashtoast[current.uuid];
            hashtoast[current.uuid] = true;
            return exists;
          })).concat(state.notificationData.toast),
          "mail": ((action.payload.notification.filter((item) => { return item.transport === 2 })).filter(function (current) {
            var exists = !hashmail[current.uuid];
            hashmail[current.uuid] = true;
            return exists;
          })).concat(state.notificationData.mail),

          "sms": ((action.payload.notification.filter((item) => { return item.transport === 3 })).filter(function (current) {
            var exists = !hashsms[current.uuid];
            hashsms[current.uuid] = true;
            return exists;
          })).concat(state.notificationData.sms)
        }
      })


    case "REMOVE_TOAST_NOTIFICATION":
      return Object.assign({}, state, {
        notificationToast: state.notificationToast.filter((notification) => {
          return notification.uuid !== action.payload.uuid
        }),
        errorToast: null
      })

    case "SET_UPDATE_NOTIFICATION_DETAIL":
      return Object.assign({}, state, {
        notificationUpdateDetail: action.payload
      })


    //blue light boost
    case 'SET_BLUEONOFF_OVERRIDE':
      return { ...state, group: { ...state.group, blue_light_boost: action.payload.blue_light_boost, blue_light_boost_minutes: action.payload.blue_light_boost_minutes, blue_light_boost_timestamp: action.payload.blue_light_boost_timestamp } };

    //circadian rhythm
    case 'SET_CIRCADIAN_OVERRIDE':
      return { ...state, group: { ...state.group, circadian_rhythm: action.payload.circadian_rhythm, circadian_start_date_time: action.payload.circadian_start_date_time } };

    default:
      return state
  }
}

export default user