import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { Form, Row, Col, message } from "antd"
import Loading from "../Loading"

import { loadUser2faQrLogin, loadUser2faQr } from '../../api'
import localStorageUtil from "../../utils/localStorageUtil"

const User2faQr = ({
  isLogin
}) => {
  const [loading, setLoading] = useState(true)
  const [qrCode, setQrCode] = useState("")

  useEffect(
    () => {
      (async () => {
        const uuid = localStorageUtil.get('user_uuid')

        let datavalue = null
        if (isLogin) {
          datavalue = await loadUser2faQrLogin(uuid)
        } else {
          datavalue = await loadUser2faQr(uuid)
        }

        if (datavalue.error) {
          message.error(datavalue.message);

        } else {
          const data = datavalue.data
          setQrCode(data.img)
        }
        setLoading(false)
      })()
    },
    []
  )

  return loading ? (
    <Loading />
  ) : (
      <>
        <Row
          type="flex"
          justify="center"
          align="middle"
        >
          <Col>
            <h3>Your 2fa QR code:</h3>
          </Col>

        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
        >
          <Col>
            {
              qrCode !== "" ? (<img src={"data:image/jpeg;base64," + qrCode} alt="img_data" id="imgslot" />)
                :
                (<div>Unable to load QR</div>)}

          </Col>
        </Row>
      </>
    )

};

const WrappedUser2faQr = Form.create()(User2faQr);

export default withRouter(WrappedUser2faQr)