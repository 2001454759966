import React, { useState, useEffect } from "react";
import { Menu, Icon } from "antd";
import { connect } from 'react-redux'

import "./style.css";
import { groupTypes } from '../../utils/enums'

const { SubMenu } = Menu;

const CompanyMenuItem = ({ xgroup, menumovil, thecompanies, select, gloading }) => {
  const [selected, setSelected] = useState("");
  const [selectKey, setSelectKey] = useState([]);
  const [openKey, setOpenKey] = useState([]);
  const [first, setFirst] = useState(true);

  const [logicalOpenKey, setLogicalOPenKey] = useState("");

  const selectItem = (group) => {
    //if(selected.uuid !== group.uuid){
    select(group)
    setSelected(group.uuid)
    //}
  }

  const selectedClass = (uuid) => {
    if (selected === uuid) {
      return "group-item-active"
    } else {
      return ""
    }
  }

  const renderbuilding = (build) => {
    return (
      <SubMenu
        key={build.uuid}
        disabled={gloading}
        title={
          <span>
            <Icon type="hdd" />
            <span>{build.name}</span>
          </span>
        }
      >
        {(build.groups ? build.groups.map((group, i) =>
          rendergroup(group)
        ) : "")}

      </SubMenu>
    );
  };



  const rendergroup = (group) => {
    //if (!menumovil) {
      if ((group.group_type === groupTypes.CONTROL) && first) {
        if (xgroup.uuid) {
        } else {
          setFirst(false);
          let newselect = ([thecompanies[0].buildings[0].uuid, group.uuid])
          let newopen = [thecompanies[0].uuid, thecompanies[0].buildings[0].uuid];
          let newgroup = thecompanies[0].buildings[0].groups[0]
          while ((newgroup.children).length > 0) {
            newopen.push(newgroup.uuid);
            newselect.push(newgroup.uuid);
            newgroup = newgroup.children[0];
          }
          if (newgroup.uuid === group.uuid) {
            setOpenKey(newopen)
            setSelectKey(newselect)
            selectItem(group)
          }
        }
      }
    //}


    /*
    <SubMenu
            key={group.uuid}
            disabled={gloading}
            className={selectedClass(group.uuid)}
            title={
              <span >
                <Icon type="apartment" />
                <span>{group.name}</span>
              </span>
            }
            onTitleClick={
              () => { selectItem(group) }
            }

          >
            {rendergroup(child)}
          </SubMenu>
          */

    // && group.in_user
    return (
      ((group.group_type === groupTypes.LOGICAL || group.group_type === groupTypes.MIX) && group.in_user ?
        //group.children.map((child, i) =>
        //rendergroup(child) ant-menu-item group-item-active ant-menu-item-selected
        <SubMenu
          key={group.uuid}
          disabled={gloading}
          className={selectedClass(group.uuid)}
          title={
            <span >
              <Icon type="apartment" />
              <span>{group.name}</span>
            </span>
          }
          onTitleClick={
            () => { selectItem(group) }
          }

        >
          {(group.children ? group.children.map((child, i) =>
            rendergroup(child)
          ) : "")}
        </SubMenu>
        //)
        :

        (group.group_type === groupTypes.CONTROL ?
          <Menu.Item
            key={group.uuid}
            disabled={gloading}
            className={selectedClass(group.uuid)}
            onClick={
              () => { selectItem(group) }
            }
          ><Icon type="team" />{group.name}</Menu.Item>
          : (group.children ? group.children.map((child, i) =>
            rendergroup(child)
          ) : ""))
      )
    );
  };

  return (
    <Menu
      disabled={gloading}
      defaultSelectedKeys={selectKey}
      defaultOpenKeys={openKey}
      inlineIndent={5}
      mode="inline"
    >
      {(
        thecompanies ? thecompanies.map((comp, i) =>
          <SubMenu
            key={comp.uuid}
            disabled={gloading}
            title={
              <span >
                <Icon type="bank" />
                <span>{comp.name}</span>
              </span>
            }
          >

            {(comp.buildings ? comp.buildings.map((build, i) =>
              renderbuilding(build)
            ) : "")}

          </SubMenu>)
          : "")}
    </Menu>
  ) ;
};

const mapStateToProps = state => {
  return {
    xgroup: state.user.group,
    gloading: state.user.loading,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {

  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyMenuItem);
//export default CompanyMenuItem;