const initialState = {
  userSavedConfigurationsData: null,
}

const userSavedConfigurations = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_SAVED_CONFIGURATIONS':
      return Object.assign({}, state, {
        userSavedConfigurationsData: action.payload,
      })

    case 'DELETE_USER_SAVED_CONFIGURATION':
      let newConfigurations = null
      for (let i = 0; i < state.userSavedConfigurationsData.length; i++) {
        const current_conf_lent = state.userSavedConfigurationsData[i].configurations.length
        const new_configurations = state.userSavedConfigurationsData[i].configurations.filter(conf => conf.uuid !== action.payload.config_uuid)
        if (current_conf_lent !== new_configurations.length) {
          if (new_configurations.length === 0) {
            newConfigurations = state.userSavedConfigurationsData.filter(group => group.group_uuid !== state.userSavedConfigurationsData[i].group_uuid)
            break
          } else {
            newConfigurations = [...state.userSavedConfigurationsData]
            newConfigurations[i].configurations = [...new_configurations]
            break
          }
        }
      }

      return Object.assign({}, state, {
        userSavedConfigurationsData: newConfigurations
      })

    case 'UPDATE_USER_SAVED_CONFIGURATION':
      let newConfigurations2 = [...state.userSavedConfigurationsData]
      for (let i = 0; i < state.userSavedConfigurationsData.length; i++) {
        for (let j = 0; j < state.userSavedConfigurationsData[i].configurations.length; j++) {
          if (state.userSavedConfigurationsData[i].configurations[j].uuid === action.payload.uuid) {
            newConfigurations2[i].configurations[j] = Object.assign({}, newConfigurations2[i].configurations[j], {
              name: action.payload.name,
              description: action.payload.description,
              uuid: action.payload.uuid
            })
          }
        }
      }

      return Object.assign({}, state, {
        userSavedConfigurationsData: newConfigurations2
      })

    case 'CREATE_USER_SAVED_CONFIGURATION':
      let newConfigurations3 = [...state.userSavedConfigurationsData]
      let check = true
      for (let i = 0; i < state.userSavedConfigurationsData.length; i++) {
        if (state.userSavedConfigurationsData[i].group_uuid === action.payload.group_uuid) {
          newConfigurations3[i].configurations.push({
            ...action.payload
          })
          check = false
        }
      }

      if (newConfigurations3.length === 0 || check ){
        newConfigurations3.push({
          group_uuid: action.payload.group_uuid,
          group_name: action.payload.group_name,
          configurations: [{
            ...action.payload
          }]
        }) 
      }

      return Object.assign({}, state, {
        userSavedConfigurationsData: newConfigurations3
      })

    default:
      return state
  }
}

export default userSavedConfigurations