import React, { useState, useEffect } from "react"
import localStorageUtil from "../../utils/localStorageUtil"
import { connect } from 'react-redux';
import { Row, Col, Card, Form, Icon, Input, Button, message } from 'antd';

import { updateUserSavedConfigurationAction, createUserSavedConfigurationAction } from '../../actions'
import { updatedUserSavedConfiguration, createUserSavedConfiguration } from '../../api'
import CenteredRow from "../CenteredRow"

//import "./style.css";

const TextArea = Input.TextArea


const UserSavedConfigurationForm = ({
  form
  , _updateUserSavedConfigurationAction
  , _createUserSavedConfigurationAction
  , type
  , setIsModalVisible
  , isModalVisible
  , group
  , configuration
  , configurationValues
}) => {
  const [loading, setLoading] = useState(false);

  //const customData = require('../../config.json')
  const customData = window.Configs;

  const handleSubmit = async e => {
    e.preventDefault();
    await validateFields(async (err, values) => {
      setLoading(true)
      if (!err) {
        const { name, description } = values
        const user_uuid = localStorageUtil.get('user_uuid')

        let datavalue = null
        if (type === "add") {
          datavalue = await createUserSavedConfiguration(user_uuid, group.uuid, { name, description })
        } else {
          datavalue = await updatedUserSavedConfiguration(user_uuid, configuration.uuid, { name, description })
        }


        if (datavalue.error) {
          message.error(datavalue.message);
          setLoading(false);
        } else {
          const data = datavalue.data

          resetFields()

          setLoading(false)
          const text = type === "add" ? "created" : "updated"
          message.success(`Configuration successfully ${text}`)

          if (type === "update") {
            _updateUserSavedConfigurationAction(data)
          } else {
            data.group_uuid = group.uuid
            data.group_name = group.name
            _createUserSavedConfigurationAction(data)
          }
          setIsModalVisible(false)
        }

      } else {
        message.error(err);
        setLoading(false);
      }
    });
  }

  const handleCancel = (e) => {
    setIsModalVisible(false)
    resetFields()
  }

  useEffect(
    () => {
      if (!isModalVisible) {
        resetFields()
      }
    },
    [isModalVisible]
  )

  const { getFieldDecorator, validateFields, resetFields } = form;
  return (
    <Row
      type="flex"
      justify="center"
    >
      <Form onSubmit={handleSubmit} className="login-form" style={{ margin: "auto" }}>
        <Form.Item label="Configuration name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input your email!' }],
            initialValue: type === "update" && configuration ? configuration.name : ""
          })(
            <Input
              disabled={loading}
              placeholder="Name"
            />,
          )}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            initialValue: type === "update" && configuration ? configuration.description : ""
          })(
            <TextArea
              disabled={loading}
              placeholder="Configuration description"
            />
          )}
        </Form.Item>

        {type === "update" && (
          <Form.Item>
            {configurationValues(configuration)}
          </Form.Item>
        )}

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "40%" }}
            loading={loading}
            size="large"
            icon={!loading && (type === "update" ? "upload" : "plus")}
            onClick={() => handleSubmit}>
            Save
              </Button>
          <Button
            type="default"
            htmlType="button"
            onClick={handleCancel}
            icon={"close"}
            style={{ width: "40%", marginLeft: "1em" }}
            size="large"
          >
            Cancel
          </Button>
        </Form.Item>

      </Form>

    </Row>
  );

}

const WrappedUserSavedConfigurationForm = Form.create({ name: 'user_saved_configuration_form' })(UserSavedConfigurationForm);

const mapStateToProps = state => {
  return {
    group: state.user.group,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _updateUserSavedConfigurationAction: form => {
      dispatch(updateUserSavedConfigurationAction(form))
    },
    _createUserSavedConfigurationAction: form => {
      dispatch(createUserSavedConfigurationAction(form))
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedUserSavedConfigurationForm);
