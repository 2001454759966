const axios = require("axios");

//const REACT_APP_ADMIN_API = process.env.REACT_APP_ADMIN_API;
//const customData = require('../config.json');
const customData = window.Configs;

const REACT_APP_ADMIN_API = customData.REACT_APP_ADMIN_API;
const TENANT = customData.TENANT;

axios.defaults.headers.common["Content-Type"] = "application/json";

// Add Authorization token to request header globally
export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

//! App login
export const loginService = form => {
  return axios({
    method: "POST",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/login_user",
    data: form
  });
};

export const login2faService = form => {
  return axios({
    method: "POST",
    url: REACT_APP_ADMIN_API+"/"+TENANT+"/login2fa",
    data: form
  })
}

export const login2faSecondStepService = form => {
  return axios({
    method: "POST",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/login2fastep2",
    data: form
  })
}

export const forgotPasswordService = form => {
  return axios({
    method: "POST",
    url: REACT_APP_ADMIN_API + "/password_forgot",
    data: form
  })
}

export const resetPasswordService = form => {
  return axios({
    method: "POST",
    url: REACT_APP_ADMIN_API + "/password_reset",
    data: form
  })
}

//! App refresh token
export const refreshTokenService = refreshtoken => {
  //axios.defaults.headers.common["Authorization"] = `Bearer ${refreshtoken}`;
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/login",
  })
}

// user profile and 2fa qr code
export const getUser2faQrService = uuid => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+uuid+"/qrcode"
  })
}

export const getUser2faQrLoginService = uuid => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+uuid+"/qrcode_login"
  })
}

export const getUserProfileService = uuid => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+uuid+"/profile"
  })
}

export const putUserProfileService = (uuid, form) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+uuid+"/profile",
    data: form
  })
}


//! App companies
export const allCompaniesService = company_uuid => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/company/18de58d3-db8d-4636-9eeb-c600d6004d26"
  })
}

//! App filter companies
export const allFilterCompaniesService = username => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/username/"+username+"/companies"
  })
}


//! App get group
export const getGroup = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group
  });
};

//! App get lightcapability
export const getLightCapability = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/lightcapability"
  });
};

//! App put on detect off group
export const getONDETECTOFF = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/on_detect_off"
  });
};

export const putONDETECTOFF = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/on_detect_off",
    data: value
  });
};

// on detect off with override
export const getONDETECTOFFOverride = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/on_detect_off/propagation"
  });
};

export const putONDETECTOFFOverride = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/on_detect_off/propagation",
    data: value
  });
};

//! App put brightness (light level)
export const getLight_Level = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/level",
  });
};

export const putLight_Level = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/level",
    data: value
  });
};

//brightness and light color temperature
export const getLightLevelOverride = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/level/propagation",
  });
};

export const putLightLevelOverride = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/level/propagation",
    data: value
  });
};

//! App put warm white level (temperature)
export const getWarmWhite_Level = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/white",
  });
};

export const putWarmWhite_Level = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/white",
    data: value
  });
};




//! App put level in schedule (schedule light level)
/*
{
    "name" : "schedule1234_22",
    "light_level" : 0,
    "timezone" : "Canada/Eastern",
    "periods": [

    ]
}
*/

export const getSchedule = (group, value) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule"
  });
};

export const postSchedule = (group, value) => {
  return axios({
    method: "post",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule",
    data: value
  });
};

export const postScheduleColorTemperature = (group, value) => {
  return axios({
    method: "post",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/colortemperature",
    data: value
  });
};

export const getSchedule_Level = (group, schedule) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/"+schedule+"/level",
  });
};

export const putSchedule_Level = (group, schedule, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/"+schedule+"/level",
    data: value
  });
};

export const getSchedule_LevelColorTemperature = (group, schedule) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/colortemperature/"+schedule+"/level",
  });
};

export const putSchedule_ColorTemperature = (group, schedule, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/colortemperature/"+schedule+"/level",
    data: value
  });
};

//! App put level in schedule (schedule light level)
export const getSchedule_Period = (group, schedule) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/"+schedule+"/period"
  });
};

export const getSchedule_Period_Levels = (group, schedule) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/colortemperature/"+schedule+"/period"
  });
};

export const postSchedule_Period = (group, schedule, value) => {
  return axios({
    method: "post",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/"+schedule+"/period",
    data: value
  });
};

export const postSchedule_Period_Levels = (group, schedule, value) => {
  return axios({
    method: "post",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/colortemperature/"+schedule+"/period",
    data: value
  });
};

export const deleteSchedule_Period = (group, schedule, period) => {
  return axios({
    method: "delete",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/"+schedule+"/period/"+period
  });
};

export const putSchedule_Period = (group, schedule, period, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/"+schedule+"/period/"+period,
    data: value
  });
};

export const putSchedule_Period_Levels = (group, schedule, period, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/schedule/colortemperature/"+schedule+"/period/"+period,
    data: value
  });
};



export const getSensorData = (group_uuid, type) =>{
    return axios({
      method: "get",
      url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group_uuid+"/latest_sensordata/"+type+"/threading"
    });
  };



//! App put brightness (light level)
export const getLUX = group_uuid => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group_uuid+"/lux",
  });
};

export const putLUX = (group_uuid, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group_uuid+"/lux",
    data: value
  });
};


export const getChartSensorDataMotion = (group_uuid, sensor, start, end) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group_uuid+"/historic_sensordata/"+sensor+"/start/"+start+"/end/"+end+"/threading",
    //cancelToken: window.sourceMotion.token
  });
};

export const getChartSensorDataPower = (group_uuid, sensor, start, end) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group_uuid+"/historic_sensordata/"+sensor+"/start/"+start+"/end/"+end+"/threading",
    //cancelToken: window.sourcePower.token
  });
};



//advanced schedule
export const getAdvancedSchedule = (group ) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule"
  });
};

export const putAdvancedSchedule_Enabled = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule/enabled",
    data: value
  });
};

export const getAllAdvancedSchedule = (group, value) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule"
  });
};

export const postAdvancedSchedule = (group, value) => {
  return axios({
    method: "post",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule",
    data: value
  });
};

export const putAdvancedSchedule_Level = (group, schedule_uuid, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule/"+schedule_uuid+"/level",
    data: value
  });
};

export const deleteAllAdvancedSchedule = (group, value) => {
  return axios({
    method: "delete",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule"
  });
};


export const deleteAdvancedSchedule = (group, schedule_uuid, value) => {
  return axios({
    method: "delete",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule/"+schedule_uuid
  });
};


//! App put level in schedule (schedule light level)
export const getAdvancedSchedule_Period = (group, schedule) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule/"+schedule+"/period"
  });
};

export const postAdvancedSchedule_Period = (group, schedule, value) => {
  return axios({
    method: "post",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule/"+schedule+"/period",
    data: value
  });
};


export const deleteAdvancedSchedule_Period = (group, schedule, period) => {
  return axios({
    method: "delete",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule/"+schedule+"/period/"+period
  });
};

export const putAdvancedSchedule_Period = (group, schedule, period, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/advanced_schedule/"+schedule+"/period/"+period,
    data: value
  });
};


//notification
export const getUserNotificationService = (transport) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+transport+"/notifications/receive",
  });
};

export const getNotificationLastMinutesService = (last_minutes) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/all/notifications/last_minutes/"+last_minutes,
  });
};

export const putToastNotificationService = (notification_uuid, form) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/notification/"+notification_uuid+"/notifications_receipt",
    data: form
  })
}

//blue light boost
export const getBLUEONOFFOverride = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/blue_light_boost/propagation"
  });
};

export const putBLUEONOFFOverride = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/blue_light_boost/propagation",
    data: value
  });
};

//circadian rhythm
export const getCircadianRhythmOverride = group => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/circadian_rhythm/propagation"
  });
};

export const putCircadianRhythmOverride = (group, value) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/group/"+group+"/circadian_rhythm/propagation",
    data: value
  });
};

// user saved configurations managment
export const getUserSavedConfigurationsListService = user => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+user+"/configurations"
  })
}

export const postUserSavedConfigurationService = (user, group, data) => {
  return axios({
    method: "post",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+user+"/group/"+group+"/save_configuration",
    data: data
  })
}

export const putUserSavedConfigurationActivationService = (user, data) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+user+"/config_activation",
    data: data
  })
}

export const putUserSavedConfigurationService = (user, config, data) => {
  return axios({
    method: "put",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+user+"/config/"+config+"/configuration",
    data: data
  })
}

export const getUserSavedConfigurationService = (user, config) => {
  return axios({
    method: "get",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+user+"/config/"+config+"/configuration"
  })
}

export const deleteUserSavedConfigurationService = (user, config) => {
  return axios({
    method: "delete",
    url: REACT_APP_ADMIN_API+"/secure/"+TENANT+"/user/"+user+"/config/"+config+"/configuration"
  })
}