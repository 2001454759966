import {
    loginService, login2faService, login2faSecondStepService, refreshTokenService, forgotPasswordService, resetPasswordService, getGroup, allFilterCompaniesService,
    getUser2faQrService, getUser2faQrLoginService, getUserProfileService, putUserProfileService,
    getONDETECTOFF, getONDETECTOFFOverride, getLight_Level, getLightLevelOverride, getWarmWhite_Level, getSchedule_Level,
    getSchedule_Period, getSensorData, setAuthToken,
    putONDETECTOFF, putLight_Level, putWarmWhite_Level,
    postSchedule, putSchedule_Level, postSchedule_Period, putSchedule_Period, deleteSchedule_Period,
    putSchedule_ColorTemperature, getSchedule_LevelColorTemperature, postScheduleColorTemperature, postSchedule_Period_Levels, putSchedule_Period_Levels,
    getChartSensorDataMotion, getChartSensorDataPower, putONDETECTOFFOverride, putLightLevelOverride,
    getLightCapability,
    getAdvancedSchedule, putAdvancedSchedule_Enabled, postAdvancedSchedule, deleteAllAdvancedSchedule, deleteAdvancedSchedule,
    putAdvancedSchedule_Level, postAdvancedSchedule_Period, putAdvancedSchedule_Period, deleteAdvancedSchedule_Period,
    getUserNotificationService, getNotificationLastMinutesService, putToastNotificationService,
    getBLUEONOFFOverride, putBLUEONOFFOverride, getCircadianRhythmOverride,putCircadianRhythmOverride,
    getUserSavedConfigurationsListService, getUserSavedConfigurationService, postUserSavedConfigurationService, putUserSavedConfigurationActivationService, putUserSavedConfigurationService, deleteUserSavedConfigurationService
} from '../services';
import localStorageUtil from "../utils/localStorageUtil";
import { isEmpty } from "lodash";

export const login = async (username, password) => {
    try {
        const response = await loginService({ username, password })
        if (response && response.status === 200) {
            let data = response.data;
            if (data.error) {
                return { "error": true, "message": "Wrong credentials", "data": "" }
            } else {
                return { "error": false, "message": "", "data": data }
            }
        } else {
            return { "error": true, "message": "Wrong credentials", "data": "" };
        }
    } catch (err) {
        let verbose = err.message.includes("401")
            ? "Wrong credentials"
            : err.message;
        return { "error": true, "message": verbose, "data": "" };

    }

}

export const login2fa = async (username, password) => {
    try {
        const response = await login2faService({ username, password })
        if (response && response.status === 200) {
            let data = response.data;
            if (data.error) {
                return { "error": true, "message": "Wrong credentials", "data": "" }
            } else {
                return { "error": false, "message": "", "data": data }
            }
        } else {
            return { "error": true, "message": "Wrong credentials", "data": "" };
        }
    } catch (err) {
        let verbose = err.message.includes("401")
            ? "Wrong credentials"
            : err.message;
        return { "error": true, "message": verbose, "data": "" };

    }
}

export const login2faSecondStep = async (username, verification_code) => {
    try {
        const response = await login2faSecondStepService({ username, verification_code })
        if (response && response.status === 200) {
            let data = response.data;
            if (data.error) {
                return { "error": true, "message": "Wrong credentials", "data": "" }
            } else {
                return { "error": false, "message": "", "data": data }
            }
        } else {
            return { "error": true, "message": "Wrong credentials", "data": "" };
        }
    } catch (err) {
        let verbose = err.message.includes("401")
            ? "Wrong credentials"
            : err.message;
        return { "error": true, "message": verbose, "data": "" };

    }
}

export const passwordForgot = async (email) => {
    try {
        const response = await forgotPasswordService({ email })
        if (response && response.status === 200) {
            let data = response.data;
            if (data.error) {
                return { "error": true, "message": data.message, "data": "" }
            } else {
                return { "error": false, "message": "", "data": data }
            }
        } else {
            return { "error": true, "message": "Invalid user", "data": "" };
        }
    } catch (err) {
        let verbose = err.message.includes("401")
            ? "Invalid user"
            : err.message;
        return { "error": true, "message": verbose, "data": "" };

    }

}

export const passwordReset = async (reset_token, password, password2) => {
    try {
        const response = await resetPasswordService({ reset_token, password, password2 })
        if (response && response.status === 200) {
            let data = response.data;
            if (data.error) {
                return { "error": true, "message": "Invalid user", "data": "" }
            } else {
                return { "error": false, "message": "", "data": data }
            }
        } else {
            return { "error": true, "message": "Invalid user", "data": "" };
        }
    } catch (err) {
        let verbose = err.message.includes("401")
            ? "Invalid user"
            : err.message;
        return { "error": true, "message": verbose, "data": "" };

    }
}

// user profile
export const loadUser2faQr = async (uuid) => {
    try {
        const response = await getUser2faQrService(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getUser2faQrService(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadUser2faQrLogin = async (uuid) => {
    try {
        const response = await getUser2faQrLoginService(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getUser2faQrLoginService(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadUserProfile = async (uuid) => {
    try {
        const response = await getUserProfileService(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getUserProfileService(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updateUserProfile = async (uuid, active_2fa) => {
    try {
        const response = await putUserProfileService(uuid, { active_2fa });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putUserProfileService(uuid, { active_2fa });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadCompanies = async (username) => {
    try {
        const response = await allFilterCompaniesService(username)
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken();
            if (check === true) {
                try {
                    const response2 = await allFilterCompaniesService(username);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


export const loadGroup = async (uuid) => {
    try {
        const response = await getGroup(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getGroup(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadLightCapability = async (uuid) => {
    try {
        const response = await getLightCapability(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getLightCapability(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadGroupONDETECTOFF = async (uuid) => {
    try {
        const response = await getONDETECTOFF(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getONDETECTOFF(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadGroupONDETECTOFFOverride = async (uuid) => {
    try {
        const response = await getONDETECTOFFOverride(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getONDETECTOFFOverride(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadGroupLightLevel = async (uuid) => {
    try {
        const response = await getLight_Level(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getLight_Level(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadGroupLightLevelOverride = async (uuid) => {
    try {
        const response = await getLightLevelOverride(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getLightLevelOverride(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadGroupWhiteLevel = async (uuid) => {
    try {
        const response = await getWarmWhite_Level(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getWarmWhite_Level(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadScheduleLevel = async (group_uuid, schedule_uuid) => {
    try {
        const response = await getSchedule_Level(group_uuid, schedule_uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getSchedule_Level(group_uuid, schedule_uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadSchedulePeriod = async (group_uuid, schedule_uuid) => {
    try {
        const response = await getSchedule_Period(group_uuid, schedule_uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getSchedule_Period(group_uuid, schedule_uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


export const loadSensorData = async (uuid, sensor_type) => {
    try {
        const response = await getSensorData(uuid, sensor_type);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getSensorData(uuid, sensor_type);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


export const updatedONDETECTOFF = async (uuid, on, shutdown) => {
    try {
        const response = await putONDETECTOFF(uuid, { "on": on, "shutdown": shutdown });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putONDETECTOFF(uuid, { "on": on, "shutdown": shutdown });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedONDETECTOFFOverride = async (uuid, on, shutdown, max_on_secs, max_off_secs) => {
    try {
        const response = await putONDETECTOFFOverride(uuid, { "on": on, "shutdown": shutdown, "max_on_secs": max_on_secs, "max_off_secs": max_off_secs });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putONDETECTOFFOverride(uuid, { "on": on, "shutdown": shutdown, "max_on_secs": max_on_secs, "max_off_secs": max_off_secs });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedLightLevel = async (uuid, slider_level) => {
    try {
        const response = await putLight_Level(uuid, { "slider_level": slider_level });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putLight_Level(uuid, { "slider_level": slider_level });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedLightLevelOverride = async (uuid, form) => {
    try {
        const response = await putLightLevelOverride(uuid, form);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putLightLevelOverride(uuid, form);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedWarmWhiteLevel = async (uuid, white_level, warm_white_level) => {
    try {
        const response = await putWarmWhite_Level(uuid, { "white_level": white_level, "warm_white_level": warm_white_level });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putWarmWhite_Level(uuid, { "white_level": white_level, "warm_white_level": warm_white_level });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

//SCHEDULE
export const createdSchedule = async (uuid, name, light_level, zone) => {
    try {
        const response = await postSchedule(uuid, {
            "name": name,
            "light_level": light_level,
            "timezone": zone,
            "periods": [
            ]
        });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await postSchedule(uuid, {
                        "name": name,
                        "light_level": light_level,
                        "timezone": zone,
                        "periods": [
                        ]
                    });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const createScheduleWithColorTemperature = async (uuid, name, light_level, white_level, warm_white_level, zone) => {
    try {
        const response = await postScheduleColorTemperature(uuid, {
            "name": name,
            "light_level": light_level,
            "white_level": white_level,
            "warm_white_level": warm_white_level,
            "timezone": zone,
            "periods": [
            ]
        });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await postScheduleColorTemperature(uuid, {
                        "name": name,
                        "light_level": light_level,
                        "white_level": white_level,
                        "warm_white_level": warm_white_level,
                        "timezone": zone,
                        "periods": [
                        ]
                    });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedScheduleLevel = async (group_uuid, schedule_id, light_level) => {
    try {
        const response = await putSchedule_Level(group_uuid, schedule_id, { "light_level": light_level });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putSchedule_Level(group_uuid, schedule_id, { "light_level": light_level });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedScheduleColorTemperature = async (group_uuid, schedule_id, light_level, white_level, warm_white_level) => {
    const form =  { light_level, white_level, warm_white_level }
    try {
        const response = await putSchedule_ColorTemperature(group_uuid, schedule_id, form)
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putSchedule_ColorTemperature(group_uuid, schedule_id, form)
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const createdPeriod = async (group_uuid, schedule_uuid, start, stop) => {
    try {
        const response = await postSchedule_Period(group_uuid, schedule_uuid, { start_time: start, stop_time: stop });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await postSchedule_Period(group_uuid, schedule_uuid, { start_time: start, stop_time: stop });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const createdPeriodWithLevels = async (group_uuid, schedule_uuid, start, stop, light_level, white_level, warm_white_level) => {
    const form = { start_time: start, stop_time: stop, light_level, white_level, warm_white_level }
    try {
        const response = await postSchedule_Period_Levels(group_uuid, schedule_uuid, form);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await postSchedule_Period_Levels(group_uuid, schedule_uuid, form);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedPeriod = async (group_uuid, schedule_uuid, period_uuid, start, stop) => {
    try {
        const response = await putSchedule_Period(group_uuid, schedule_uuid, period_uuid, { start_time: start, stop_time: stop });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putSchedule_Period(group_uuid, schedule_uuid, period_uuid, { start_time: start, stop_time: stop });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedPeriod_Levels = async (group_uuid, schedule_uuid, period_uuid, start, stop, light_level, white_level, warm_white_level) => {
    const form = { start_time: start, stop_time: stop, light_level, white_level, warm_white_level }
    try {
        const response = await putSchedule_Period_Levels(group_uuid, schedule_uuid, period_uuid, form);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putSchedule_Period_Levels(group_uuid, schedule_uuid, period_uuid, form);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const deletedPeriod = async (group_uuid, schedule_uuid, period_id) => {
    try {
        const response = await deleteSchedule_Period(group_uuid, schedule_uuid, period_id);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await deleteSchedule_Period(group_uuid, schedule_uuid, period_id);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


export const loadChartSensorDataMotion = async (uuid, sensor, start, end) => {
    try {
        const response = await getChartSensorDataMotion(uuid, sensor, start, end);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getChartSensorDataMotion(uuid, sensor, start, end);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadChartSensorDataPower = async (uuid, sensor, start, end) => {
    try {
        const response = await getChartSensorDataPower(uuid, sensor, start, end);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getChartSensorDataPower(uuid, sensor, start, end);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


const tryInternalResponse = (response) => {
    if (response && response.status === 200) {
        let data = response.data;
        if (data.error) {
            return { "error": true, "message": (data.message ? data.message :"Server Error"), "data": "" }
        } else {
            return { "error": false, "message": "", "data": data }
        }
    } else {
        return { "error": true, "message": "Server Error", "data": "" }
    }
}

const tryServerError = (err) => {
    if (err.response && err.response.status && err.response.status === 401) {
        return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
    } else {
        return { "error": true, "message": err.message, "data": "" }
    }
}

const checkRefreshToken = async () => {
    if (isEmpty(localStorageUtil.getToken()) || isEmpty(localStorageUtil.get('username'))) {
        return false;
    }
    try {
        //await setAuthToken(data.access_token);
        await setAuthToken(localStorageUtil.getRefreshToken());
        const response = await refreshTokenService(localStorageUtil.getRefreshToken())
        if (response && response.status === 200) {
            let data = response.data;
            if (data.error) {
                return false;
            } else {
                await localStorageUtil.setToken(data.access_token);
                await localStorageUtil.setRefreshToken(data.refresh_token);
                await localStorageUtil.set(data.username, 'username');
                await setAuthToken(data.access_token);
                return true;
            }
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}



export const loadAdvancedSchedule = async (group_uuid) => {
    try {
        const response = await getAdvancedSchedule(group_uuid)
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getAdvancedSchedule(group_uuid)
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


export const updatedAdvancedSchedule_Enabled = async (group_uuid, advanced_schedule_enabled) => {
    const form =  { advanced_schedule_enabled }
    try {
        const response = await putAdvancedSchedule_Enabled(group_uuid, form)
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putAdvancedSchedule_Enabled(group_uuid, form)
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


export const createAdvancedSchedule = async (uuid, name, schedule_type, light_level, white_level, warm_white_level, zone) => {
    try {
        const response = await postAdvancedSchedule(uuid, {
            "name": name,
            "light_level": light_level,
            "white_level": white_level,
            "warm_white_level": warm_white_level,
            "schedule_type": schedule_type,
            "timezone": zone,
            "advanced_schedule_periods": [
            ]
        });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await postAdvancedSchedule(uuid, {
                        "name": name,
                        "light_level": light_level,
                        "white_level": white_level,
                        "warm_white_level": warm_white_level,
                        "schedule_type": schedule_type,
                        "timezone": zone,
                        "advanced_schedule_periods": [
                        ]
                    });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}



export const removeAllAdvancedSchedule = async (uuid) => {
    try {
        const response = await deleteAllAdvancedSchedule(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await deleteAllAdvancedSchedule(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const removeAdvancedSchedule = async (uuid, schedule_uuid) => {
    try {
        const response = await deleteAdvancedSchedule(uuid, schedule_uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await deleteAdvancedSchedule(uuid, schedule_uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}



export const updatedAdvancedSchedule_Level = async (group_uuid, schedule_id, light_level, white_level, warm_white_level) => {
    const form =  { light_level, white_level, warm_white_level }
    try {
        const response = await putAdvancedSchedule_Level(group_uuid, schedule_id, form)
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putAdvancedSchedule_Level(group_uuid, schedule_id, form)
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const createdAdvancedPeriod = async (group_uuid, schedule_uuid, start, stop, light_level, white_level, warm_white_level) => {
    const form = { start_time: start, stop_time: stop, light_level, white_level, warm_white_level }
    try {
        const response = await postAdvancedSchedule_Period(group_uuid, schedule_uuid, form);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await postAdvancedSchedule_Period(group_uuid, schedule_uuid, form);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedAdvancedPeriod = async (group_uuid, schedule_uuid, period_uuid, start, stop, light_level, white_level, warm_white_level) => {
    const form = { start_time: start, stop_time: stop, light_level, white_level, warm_white_level }
    try {
        const response = await putAdvancedSchedule_Period(group_uuid, schedule_uuid, period_uuid, form);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putAdvancedSchedule_Period(group_uuid, schedule_uuid, period_uuid, form);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const deletedAdvancedPeriod = async (group_uuid, schedule_uuid, period_id) => {
    try {
        const response = await deleteAdvancedSchedule_Period(group_uuid, schedule_uuid, period_id);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await deleteAdvancedSchedule_Period(group_uuid, schedule_uuid, period_id);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


//notification
export const getUserNotification = async (transport) => {
    try {
        const response = await getUserNotificationService(transport);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getUserNotificationService(transport);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const getNotificationLastMinutes = async (last_minutes) => {
    try {
        const response = await getNotificationLastMinutesService(last_minutes);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getNotificationLastMinutesService(last_minutes);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedToastNotificationShow = async (notification_uuid, form) => {
    try {
        const response = await putToastNotificationService(notification_uuid, form);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putToastNotificationService(notification_uuid, form);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}


export const loadGroupBLUEONOFFOverride = async (uuid) => {
    try {
        const response = await getBLUEONOFFOverride(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getBLUEONOFFOverride(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedBLUEONOFFOverride = async (uuid, value) => {
    try {
        const response = await putBLUEONOFFOverride(uuid, { "blue_light_boost": value });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putBLUEONOFFOverride(uuid, { "blue_light_boost": value });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadGroupCircadianRhythmOverride = async (uuid) => {
    try {
        const response = await getCircadianRhythmOverride(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getCircadianRhythmOverride(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedCircadianRhythmOverride = async (uuid, value) => {
    try {
        const response = await putCircadianRhythmOverride(uuid, { "circadian_rhythm": value });
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putCircadianRhythmOverride(uuid, { "circadian_rhythm": value });
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

//apis for user saved configurations
export const loadUserSavedConfigurationsList = async (uuid) => {
    try {
        const response = await getUserSavedConfigurationsListService(uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getUserSavedConfigurationsListService(uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const loadUserSavedConfiguration = async (user_uuid, config_uuid) => {
    try {
        const response = await getUserSavedConfigurationService(user_uuid, config_uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await getUserSavedConfigurationService(user_uuid, config_uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const updatedUserSavedConfiguration = async (user_uuid, config_uuid, data) => {
    try {
        const response = await putUserSavedConfigurationService(user_uuid, config_uuid, data);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putUserSavedConfigurationService(user_uuid, config_uuid, data);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const activatedUserSavedConfiguration = async (user_uuid, data) => {
    try {
        const response = await putUserSavedConfigurationActivationService(user_uuid, data);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await putUserSavedConfigurationActivationService(user_uuid, data);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const deleteUserSavedConfiguration = async (user_uuid, config_uuid) => {
    try {
        const response = await deleteUserSavedConfigurationService(user_uuid, config_uuid);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await deleteUserSavedConfigurationService(user_uuid, config_uuid);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

export const createUserSavedConfiguration = async (user_uuid, group_uuid, data) => {
    try {
        const response = await postUserSavedConfigurationService(user_uuid, group_uuid, data);
        return tryInternalResponse(response);
    } catch (err) {
        if (err.response && err.response.status && err.response.status === 401) {
            const check = await checkRefreshToken(); 
            if (check === true) {
                try {
                    const response2 = await postUserSavedConfigurationService(user_uuid, group_uuid, data);
                    return tryInternalResponse(response2);
                } catch (err2) {
                    return tryServerError(err2);
                }
            } else {
                return { "error": true, "autherror": true, "message": err.response.data.msg, "data": "" }
            }
        } else {
            return { "error": true, "message": err.message, "data": "" }
        }
    }
}

