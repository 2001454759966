import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Slider, Input, TimePicker, Button, Tooltip, message, Select, Switch, Checkbox } from "antd";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import moment from 'moment';

import localStorageUtil from "../../../utils/localStorageUtil";
import ScheduleModal from "../../../components/ScheduleModal";

import Loader from "../../../components/Loading"
import { lightColorTemperatureFormatter } from "../../../utils/lightLevelsColorTemperature"

import { loadAdvancedSchedule, updatedAdvancedSchedule_Enabled, createAdvancedSchedule, removeAllAdvancedSchedule, removeAdvancedSchedule, updatedAdvancedSchedule_Level, createdAdvancedPeriod, updatedAdvancedPeriod, deletedAdvancedPeriod } from "../../../api"

import { setGroup, setAdvancedScheduleEnabled, setAdvancedSchedule, addAdvancedSchedule, delAdvancedSchedule, setAdvancedScheduleLevel, setAdvancedScheduleWhiteLevel, setAdvancedSchedulePeriod, addAdvancedSchedulePeriod, delAdvancedSchedulePeriod, setLoading, setEmptyChartData } from '../../../actions';
import "./style.css";

const { Option } = Select;

const { TabPane } = Tabs;

const tabs = {
  "Week": 0,
  "Weekdays": 1,
  "Weekend": 2,
  "Monday": 3,
  "Tuesday": 4,
  "Wednesday": 5,
  "Thursday": 6,
  "Friday": 7,
  "Saturday": 8,
  "Sunday": 9
}


const AdvancedSchedule = ({ group, _setGroup, _setAdvancedScheduleEnabled, _setAdvancedSchedule, _addAdvancedSchedule, _delAdvancedSchedule, _setAdvancedScheduleLightLevel, _setAdvancedScheduleWhiteLevel, _setAdvancedSchedulePeriod, _addAdvancedSchedulePeriod, _delAdvancedSchedulePeriod, _setGLoading, _setEmptyChartData }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState(useHistory());
  const [advancedScheduleEnabled, setAdvancedScheduleEnabled] = useState(false);

  const [advancedScheduleType, setAdvancedScheduleType] = useState("Week");

  const [advancedScheduleTypeWeek, setAdvancedScheduleTypeWeek] = useState([]);
  const [advancedScheduleTypeWeek2, setAdvancedScheduleTypeWeek2] = useState([]);
  const [advancedScheduleTypeDaily, setAdvancedScheduleTypeDaily] = useState([]);

  const [advancedSchedule, setAdvancedSchedule] = useState([]);

  const arrayDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  const arrayweekdays = ["Weekdays", "Weekend"]
  const arrayweek = ["Week"]


  const [lightlevel, setLightlevel] = useState(0);
  const [whiteLevel, setWhiteLevel] = useState(0);
  const [timezone, setTimezone] = useState("");
  const [periods, setPeriods] = useState([]);
  const [period, setSchedulePeriod] = useState({});
  const [isScheduleModalVisible, setShowScheduleModal] = useState(false);

  const [currentTab, setCurrentTab] = useState("0")
  const [updateIndex, setUpdateIndex] = useState(0)
  useEffect(() => {
    window.cancelschedule = false;
    setAdvancedScheduleEnabled(group.advanced_schedule_enabled && group.advanced_schedule_enabled === true ? true : false)
    if (advancedSchedule && advancedSchedule.length > 0) {

      //determinar el type
      const return_element = getAdvancedScheduleType(advancedSchedule)
      if (return_element.return_type === "Week") {
        setAdvancedScheduleTypeWeek(return_element.schedule_array)
      }
      if (return_element.return_type === "Weekdays") {
        setAdvancedScheduleTypeWeek2(return_element.schedule_array)
      }
      if (return_element.return_type === "Daily") {
        setAdvancedScheduleTypeDaily(return_element.schedule_array)
      }
      setAdvancedScheduleType(return_element.return_type)

      if (advancedSchedule && advancedSchedule.length > 0) {
        let intCurrentTab = parseInt(currentTab) + updateIndex
        setUpdateIndex(0)
        if (!advancedSchedule[intCurrentTab]) {
          setCurrentTab("0");
          intCurrentTab = 0;
        } else {
          setCurrentTab(intCurrentTab + "");
        }
        setPeriods(advancedSchedule[intCurrentTab].advanced_schedule_periods)
        setLightlevel(advancedSchedule[intCurrentTab].light_level);
        setWhiteLevel(advancedSchedule[intCurrentTab].white_level)
        setTimezone(advancedSchedule[intCurrentTab].timezone);
      }
    } else {
      //createNewAdvancedSchedule("Week")
      setAdvancedScheduleTypeWeek([])
      setAdvancedScheduleTypeWeek2([])
      setAdvancedScheduleTypeDaily([])
    }

    return () => {
      //window.cancelschedule = true;
    };
  }, [advancedSchedule])

  const updateAdvancedScheduleValue = async (group_uuid) => {
    setLoading(true);
    _setGLoading(true);
    const datachedule = await loadAdvancedSchedule(group_uuid);
    if (datachedule.error) {
      if (datachedule.autherror) {
        message.info(datachedule.message);
        logout();
      } else {
        message.error(datachedule.message);
      }
      setLoading(false);
      _setGLoading(false);
    } else {
      _setAdvancedScheduleEnabled({advanced_schedule_enabled: datachedule.data.advanced_schedule_enabled});
      _setAdvancedSchedule(datachedule.data.advanced_schedule);
    }

    setLoading(false);
    _setGLoading(false);
  }


  useEffect(() => {
    window.cancelschedule = false;
    if (group.uuid) {
      updateAdvancedScheduleValue(group.uuid);
    }

    return () => {
      //window.cancelschedule = true;
    };
  }, [group.uuid])

  useEffect(() => {
    if (group.uuid) {
      setAdvancedSchedule(group.advanced_schedule ? group.advanced_schedule.sort(function (a, b) {
        return tabs[a.schedule_type] - tabs[b.schedule_type];
      }) : [])
    }

    return () => {
      //window.cancelschedule = true;
    };
  }, [group.advanced_schedule])

  useEffect(() => {
    if (group.uuid) {
      setAdvancedScheduleEnabled(group.advanced_schedule_enabled)
    }

    return () => {
      //window.cancelschedule = true;
    };
  }, [group.advanced_schedule_enabled])


  const getAdvancedScheduleType = (advanced_schedule) => {
    let return_type = "Week"
    let tmp_array_week = []
    let tmp_array_week_days = []
    let tmp_array_days = []
    advanced_schedule.forEach(element => {
      if (element.schedule_type === "Weekdays") {
        return_type = "Weekdays"
        tmp_array_week_days.push("Weekdays")
      } else if (element.schedule_type === "Weekend") {
        return_type = "Weekdays"
        tmp_array_week_days.push("Weekend")
      } else if (element.schedule_type === "Monday") {
        return_type = "Daily"
        tmp_array_days.push("Monday")
      } else if (element.schedule_type === "Tuesday") {
        return_type = "Daily"
        tmp_array_days.push("Tuesday")
      } else if (element.schedule_type === "Wednesday") {
        return_type = "Daily"
        tmp_array_days.push("Wednesday")
      } else if (element.schedule_type === "Thursday") {
        return_type = "Daily"
        tmp_array_days.push("Thursday")
      } else if (element.schedule_type === "Friday") {
        return_type = "Daily"
        tmp_array_days.push("Friday")
      } else if (element.schedule_type === "Saturday") {
        return_type = "Daily"
        tmp_array_days.push("Saturday")
      } else if (element.schedule_type === "Sunday") {
        return_type = "Daily"
        tmp_array_days.push("Sunday")
      } else if ("Week") {
        return_type = "Week"
        tmp_array_week.push("Week")
      }
    });



    if (return_type === "Week") {
      const schedule_array = tmp_array_week
      return { return_type, schedule_array }
    }
    if (return_type === "Weekdays") {
      const schedule_array = tmp_array_week_days
      return { return_type, schedule_array }
    }
    if (return_type === "Daily") {
      const schedule_array = tmp_array_days
      return { return_type, schedule_array }
    }
    const schedule_array = tmp_array_week
    return { return_type, schedule_array }
  }


  const setSchedule_Enabled = async (value) => {
    setLoading(true);
    _setGLoading(true);
    const datavalue = await updatedAdvancedSchedule_Enabled(group.uuid, value);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _setAdvancedScheduleEnabled(
        {
          advanced_schedule_enabled: datavalue.data.advanced_schedule_enabled
        });
    }
    setLoading(false);
    _setGLoading(false);
  }

  const createNewAdvancedSchedule = async (schedule_type) => {
    setLoading(true);
    _setGLoading(true);
    let response_error = false;
    const datavalue = await createAdvancedSchedule(group.uuid, group.name, schedule_type, 0, 0, 100, "Canada/Eastern");
    if (window.cancelschedule) {
      return;
    }
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
      response_error = true
    } else {
      await _addAdvancedSchedule({
        advanced_schedule: {
          "uuid": datavalue.data.advanced_schedule.uuid,
          "id": datavalue.data.advanced_schedule.id,
          "name": datavalue.data.advanced_schedule.name,
          "schedule_type": datavalue.data.advanced_schedule.schedule_type,
          "light_level": datavalue.data.advanced_schedule.light_level,
          "white_level": datavalue.data.advanced_schedule.white_level,
          "warm_white_level": datavalue.data.advanced_schedule.warm_white_level,
          "timezone": datavalue.data.advanced_schedule.timezone,
          "advanced_schedule_periods": [
          ]
        }
      });
    }
    setLoading(false);
    _setGLoading(false);
    return response_error;
  }

  const deleteOldAdvancedSchedule = async (schedule_uuid) => {
    setLoading(true);
    _setGLoading(true);
    let response_error = false
    const datavalue = await removeAdvancedSchedule(group.uuid, schedule_uuid);
    if (window.cancelschedule) {
      return;
    }
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
      response_error = true
    } else {
      await _delAdvancedSchedule({
        "uuid": schedule_uuid
      });
    }
    setLoading(false);
    _setGLoading(false);
    return response_error;
  }

  const deleteListAdvancedSchedule = async () => {
    setLoading(true);
    _setGLoading(true);
    let response_error = false;
    const datavalue = await removeAllAdvancedSchedule(group.uuid);
    if (window.cancelschedule) {
      return;
    }
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
      response_error = true;
    } else {
      await _setAdvancedSchedule([]);
    }
    setLoading(false);
    _setGLoading(false);
    return response_error;
  }

  const callback = (key) => {
    const index = parseInt(key)
    setPeriods(advancedSchedule[index].advanced_schedule_periods)
    setLightlevel(advancedSchedule[index].light_level);
    setWhiteLevel(advancedSchedule[index].white_level)
    setTimezone(advancedSchedule[index].timezone);

    setCurrentTab(key)
  }


  const setLight_Level = async (light_level) => {
    setLoading(true);
    _setGLoading(true);
    const warmWhiteLevel = 100 - whiteLevel
    const datavalue = await updatedAdvancedSchedule_Level(group.uuid, advancedSchedule[parseInt(currentTab)].uuid, light_level, whiteLevel, warmWhiteLevel);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _setAdvancedScheduleLightLevel(
        {
          uuid: datavalue.data.advanced_schedule_uuid,
          light_level: datavalue.data.light_level
        });
    }
    setLoading(false);
    _setGLoading(false);
  }

  const setWhite_Level = async (white_level) => {
    setLoading(true);
    _setGLoading(true);
    const warm_white_level = 100 - white_level
    const datavalue = await updatedAdvancedSchedule_Level(group.uuid, advancedSchedule[parseInt(currentTab)].uuid, lightlevel, white_level, warm_white_level);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _setAdvancedScheduleWhiteLevel({
        uuid: datavalue.data.advanced_schedule_uuid,
        white_level: datavalue.data.white_level
      });
    }
    setLoading(false);
    _setGLoading(false);
  }

  const handleChangeEnabled = value => {
    setSchedule_Enabled(value)
    setAdvancedScheduleEnabled(value);
  }

  const handleChangeType = async (value) => {
    if (advancedScheduleType !== value) {
      const respose_error = await deleteListAdvancedSchedule();
      if (!respose_error) {
        await setAdvancedScheduleType(value);
        await setAdvancedScheduleTypeWeek([]);
        await setAdvancedScheduleTypeWeek2([]);
        await setAdvancedScheduleTypeDaily([]);
      }
    }


  }

  const handleChangeTypeWeek = (value, e) => {
    let newAdvancedScheduleTypeWeek = advancedScheduleTypeWeek
    newAdvancedScheduleTypeWeek[value] = !newAdvancedScheduleTypeWeek[value]
    setAdvancedScheduleTypeWeek(newAdvancedScheduleTypeWeek);
  }


  //change light
  const handleChangeLightLevel = value => {
    setLightlevel(value);
  }

  const handleChangeWhiteLevel = value => {
    setWhiteLevel(value);
  }

  //change light up
  const handleAfterChangeLightLevel = value => {
    setLight_Level(value);
  }

  const handleAfterChangeWhiteLevel = value => {
    setWhite_Level(value);
  }

  const showScheduleModal = (theperiod) => {
    setSchedulePeriod(theperiod || {})
    setShowScheduleModal(true)
  }

  const hideScheduleModal = () => {
    setSchedulePeriod(undefined)
    setShowScheduleModal(false)
  }

  const savePeriod = async (startH, startM, stopH, stopM, light_level, white_level) => {
    setLoading(true);
    _setGLoading(true);

    const { startT, stopT } = convert(startH, startM, stopH, stopM);
    const warm_white_level = 100 - white_level
    const schedule_uuid = advancedSchedule[parseInt(currentTab)].uuid

    if (period && period.id) {
      const datavalue = await updatedAdvancedPeriod(group.uuid, schedule_uuid, period.uuid, startT, stopT, light_level, white_level, warm_white_level);
      if (datavalue.error) {
        if (datavalue.autherror) {
          message.info(datavalue.message);
          logout();
        } else {
          message.error(datavalue.message);
        }
      } else {

        await _setAdvancedSchedulePeriod({
          schedule_uuid: schedule_uuid,
          advanced_schedule_period: {
            id: datavalue.data.id,
            uuid: datavalue.data.uuid,
            start_time: datavalue.data.start_time,
            stop_time: datavalue.data.stop_time,
            light_level,
            white_level,
            warm_white_level,
          }
        });
      }
    } else {
      const datavalue = await createdAdvancedPeriod(group.uuid, schedule_uuid, startT, stopT, light_level, white_level, warm_white_level);
      if (datavalue.error) {
        if (datavalue.autherror) {
          message.info(datavalue.message);
          logout();
        } else {
          message.error(datavalue.message);
        }
      } else {
        await _addAdvancedSchedulePeriod({
          schedule_uuid: schedule_uuid,
          len: periods.length,
          advanced_schedule_period: {
            id: datavalue.data.id,
            uuid: datavalue.data.uuid,
            start_time: datavalue.data.start_time,
            stop_time: datavalue.data.stop_time,
            light_level,
            white_level,
            warm_white_level,
          }
        });
      }
    }
    setLoading(false);
    _setGLoading(false);
    hideScheduleModal();

  }

  const convert = (startH, startM, stopH, stopM) => {
    //const offset = new Date().getTimezoneOffset() / 60;

    const utcstartH = ((Number)(startH)) % 24;
    const utcstopH = ((Number)(stopH)) % 24;

    const startT = (utcstartH + ":" + startM + ":00")
    const stopT = (utcstopH + ":" + stopM + ":00")

    return { startT, stopT };
  }

  const deletePeriod = async (period_uuid) => {
    setLoading(true);
    _setGLoading(true);
    const schedule_uuid = advancedSchedule[parseInt(currentTab)].uuid

    const datavalue = await deletedAdvancedPeriod(group.uuid, schedule_uuid, period_uuid);
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      await _delAdvancedSchedulePeriod({ schedule_uuid: schedule_uuid, period_uuid: period_uuid });
    }
    setLoading(false);
    _setGLoading(false);
  }

  const logout = async () => {
    await _setGroup({})
    await _setEmptyChartData()
    localStorageUtil.clearAppStorage();
    history.push("/login")
  }


  const getMoment = (date) => {
    //const getoffset = new Date().getTimezoneOffset() / 60;
    const getstartH = (Number)((date).split(':')[0]);
    const getstartM = (Number)((date).split(':')[1]);

    const getutcstart = ((Number)(getstartH)) % 24;

    const finalstartT = (getutcstart + ":" + getstartM + ":00")
    return finalstartT;
  }


  const marksbright = {
    0: {
      style: {
        fontSize: '12px'
      },
      label: <span>0%</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A"
      },
      label: <span>100%</span>,
    }
  }

  const marksColorTemperature = {
    0: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Warm White</span>
    },
    100: {
      style: {
        fontSize: '12px',
        color: "#53575A",
        minWidth: "100px",
      },
      label: <span>Bright White</span>,
    }
  }

  const onChangeDays = async (checkedValues) => {
    const error = await create_or_delete_advanced_schedule(advancedScheduleTypeDaily, checkedValues)
    if (!error) {
      setAdvancedScheduleTypeDaily(checkedValues)
    }
  }

  const onChangeWeekday = async (checkedValues) => {
    const error = await create_or_delete_advanced_schedule(advancedScheduleTypeWeek2, checkedValues)
    if (!error) {
      setAdvancedScheduleTypeWeek2(checkedValues)
    }
  }

  const onChangeWeek = async (checkedValues) => {
    const error = await create_or_delete_advanced_schedule(advancedScheduleTypeWeek, checkedValues)
    if (!error) {
      setAdvancedScheduleTypeWeek(checkedValues)
    }
  }

  function create_or_delete_advanced_schedule(old_array_schedule_type, new_array_schedule_type) {
    //determinte if is create or delete
    let error = false;
    if (old_array_schedule_type.length < new_array_schedule_type.length) {
      //create
      const new_element = Array.from(new_array_schedule_type).filter(x => old_array_schedule_type.indexOf(x) <= -1)
      if (tabs[old_array_schedule_type[parseInt(currentTab)]] > tabs[new_element[0]]) {
        setUpdateIndex(1)
      }
      error = createNewAdvancedSchedule(new_element[0]);
    } else if (old_array_schedule_type.length > new_array_schedule_type.length) {
      //delete
      const delete_element = Array.from(old_array_schedule_type).filter(x => new_array_schedule_type.indexOf(x) <= -1)
      const schedule_uuid = getAdvancedSchedule_with_type(delete_element[0])
      if (tabs[old_array_schedule_type[parseInt(currentTab)]] > tabs[delete_element[0]]) {
        setUpdateIndex(-1)
      }
      error = deleteOldAdvancedSchedule(schedule_uuid)
    }
    return error;
  }

  function getAdvancedSchedule_with_type(schedule_type) {
    let schedule_uuid = ""
    advancedSchedule.forEach(element => {
      if (element.schedule_type === schedule_type) {
        schedule_uuid = element.uuid
      }

    });
    return schedule_uuid;
  }

  return (!group.uuid ? (<div>
    <div className="big-text">No group selected</div>
  </div>) : ((group.advanced_schedule === null ? (<div>
    <div className="big-text"><div style={{ marginTop: 50 }}><Loader scope="navbar" typeClass="navbar" /></div></div>
  </div>) : (
      <>
        <div>
          <div style={{ display: "inline-block", width: "100%" }}>
            {/*
            <Col xs={8} sm={5} className="enabled-label">
              <div className="big-text">Enabled:</div>
            </Col>
            <Col xs={16} sm={19} className="enabled-control">
              <div>
                <Switch disabled={loading} defaultChecked checked={advancedScheduleEnabled} onChange={handleChangeEnabled} />
              </div>
            </Col>
            */}
            <Col xs={8} sm={5} className="type-label">
              <div className="big-text">Type:</div>
            </Col>
            <Col xs={16} sm={19} className="type-control">
              <div>
                <Select disabled={loading} defaultValue="Week" value={advancedScheduleType} style={{ width: 180 }} onChange={handleChangeType}>
                  <Option value="Week">Week</Option>
                  <Option value="Weekdays">Weekdays/Weekend</Option>
                  <Option value="Daily">Daily</Option>
                </Select>
              </div>
            </Col>
            <Col xs={8} sm={5} className="enabled-label">
            </Col>
            <Col xs={16} sm={19} className="type-control">
              {(advancedScheduleType === "Week" ? (
                <div>
                  <Checkbox.Group disabled={loading} options={arrayweek} value={advancedScheduleTypeWeek} onChange={onChangeWeek} />
                </div>
              ) : <></>)}

              {(advancedScheduleType === "Weekdays" ? (
                <div>
                  <Checkbox.Group disabled={loading} options={arrayweekdays} value={advancedScheduleTypeWeek2} onChange={onChangeWeekday} />
                </div>
              ) : <></>)}

              {(advancedScheduleType === "Daily" ? (
                <div>
                  <Checkbox.Group disabled={loading} options={arrayDays} value={advancedScheduleTypeDaily} onChange={onChangeDays} />
                </div>
              ) : <></>)}
            </Col>
          </div>

          <div>
            <Tabs disabled={loading} activeKey={currentTab} onChange={callback}>
              {advancedSchedule && advancedSchedule.map((element, index) => (
                <TabPane disabled={loading} tab={<span className="big-text">{element.schedule_type}</span>} key={index}>
                  {currentTab + "" === index + "" &&
                    <ScheduleModal
                      period={period}
                      visible={isScheduleModalVisible}
                      hideModal={hideScheduleModal}
                      savePeriod={savePeriod}
                      schedule={element}
                      group={group}
                    />
                  }
                  <div>
                    <div className="big-text">Brightness</div>
                    <div>
                      <Slider disabled={loading} step={5} marks={marksbright} min={0} max={100} onChange={handleChangeLightLevel} onAfterChange={handleAfterChangeLightLevel} value={lightlevel ? lightlevel : 0} />
                    </div>
                    <div className="big-text">Light Temperature</div>
                    <div>
                      <Slider disabled={loading} step={5} marks={marksColorTemperature} tipFormatter={lightColorTemperatureFormatter} min={0} max={100} onChange={handleChangeWhiteLevel} onAfterChange={handleAfterChangeWhiteLevel} value={whiteLevel ? whiteLevel : 0} />
                    </div>
                    <div className="big-text">Timezone</div>
                    <div>
                      <Input className="schedule-info__input" disabled value={timezone} />
                    </div>
                    <Row>
                      {periods &&
                        periods.length > 0 && periods.map((period, index) => (
                          <Col xs={24} key={period.id} className="schedule-form">
                          <Row display="flex" justify="start" className="schedule-form__times">
                              
                              <Col xs={24} sm={24} style={{marginBottom: "5px"}}>
                                <span><b>Bright: </b>{period.light_level}%</span>
                              
                                <span style={{marginLeft: "20px"}}><b>Light Temperature: </b>{lightColorTemperatureFormatter(period.white_level)}</span>
                              </Col>
                             
                              
                            </Row>
                            <Row display="flex" justify="start" className="schedule-form__times" >
                              <Col xs={12} sm={8} className="time" style={{marginBottom: "5px"}}>
                                <span className="time__title">ON</span>
                                <TimePicker
                                  use12Hours
                                  format="hh:mm a"
                                  disabled={true}
                                  value={moment(getMoment(period.start_time), "HH:mm:ss")}
                                />
                              </Col>
                              <Col xs={12} sm={8} className="time" style={{marginBottom: "5px"}}>
                                <span className="time__title">OFF</span>
                                <TimePicker
                                  use12Hours
                                  format="hh:mm a"
                                  disabled={true}
                                  value={moment(getMoment(period.stop_time), "HH:mm:ss")}
                                />
                              </Col>
                              <Col xs={24} sm={8} className="btn-advanced-schedule-period">
                                <Row display="flex">
                                  <Tooltip title="Edit Schedule">
                                    <Button
                                      type="info"
                                      className="info"
                                      shape="circle"
                                      icon="edit"
                                      style={{ marginLeft: 20 }}
                                      loading={loading}
                                      onClick={() => showScheduleModal(period)}

                                    />
                                  </Tooltip>
                                  <Tooltip title="Delete Schedule">
                                    <Button
                                      type="danger"
                                      className="delete"
                                      shape="circle"
                                      icon="delete"
                                      style={{ marginLeft: 20 }}
                                      loading={loading}
                                      onClick={() => deletePeriod(period.uuid)}
                                    />
                                  </Tooltip>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        ))
                      }
                      <Col sm={{ span: 24 }} style={{ paddingTop: 20 }}>
                        <Tooltip title="Add Schedule">
                          <Button
                            icon="plus"
                            type="primary"
                            ghost
                            className="add-schedule-btn"
                            loading={loading}
                            onClick={() => showScheduleModal()}
                          >
                            Schedule
              </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              ))}
            </Tabs>

          </div>

        </div>
      </>
    ))));
};


const mapStateToProps = state => {
  return {
    group: state.user.group
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    _setGroup: data => {
      dispatch(setGroup(data));
    },

    _setEmptyChartData: () => {
      dispatch(setEmptyChartData());
    },

    _setAdvancedScheduleEnabled: data => {
      dispatch(setAdvancedScheduleEnabled(data));
    },

    _setAdvancedSchedule: data => {
      dispatch(setAdvancedSchedule(data));
    },

    _addAdvancedSchedule: data => {
      dispatch(addAdvancedSchedule(data));
    },
    _delAdvancedSchedule: data => {
      dispatch(delAdvancedSchedule(data));
    },

    _setAdvancedScheduleLightLevel: data => {
      dispatch(setAdvancedScheduleLevel(data));
    },

    _setAdvancedScheduleWhiteLevel: data => {
      dispatch(setAdvancedScheduleWhiteLevel(data));
    },

    _setAdvancedSchedulePeriod: data => {
      dispatch(setAdvancedSchedulePeriod(data));
    },
    _addAdvancedSchedulePeriod: data => {
      dispatch(addAdvancedSchedulePeriod(data));
    },
    _delAdvancedSchedulePeriod: data => {
      dispatch(delAdvancedSchedulePeriod(data));
    },

    _setGLoading: data => {
      dispatch(setLoading(data));
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSchedule);