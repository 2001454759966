import React from "react";
import ReactDOM from "react-dom";

import 'antd/dist/antd.css';

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer  from './reducers'
import App from './App';

import * as serviceWorker from './serviceWorker';



	
const startApp = (cordovaWork) => {
	let store = createStore(rootReducer ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

	ReactDOM.render(     
      <Provider store={store}>
            <App />
      </Provider>
    ,
    document.getElementById("root"));
}
       
   
if (!window.cordova) {
    startApp(false)
} else {
    document.addEventListener("deviceready",  () => {
        startApp(true);
    }, false);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
