import React, { useEffect, useState } from "react";
import { Icon, Button, Modal, Notification, message, Badge } from "antd";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import './styles.css';
import UserNotification from "../../UserNotification";
import { getNotificationLastMinutes, updatedToastNotificationShow, getUserNotification } from "../../../api"

import localStorageUtil from "../../../utils/localStorageUtil";

const NotificationIcon = ({
  history,
  className
}) => {

  const modules = useSelector((state) => state.user.user.modules)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const notificationToastReducer = useSelector((state) => state.user.notificationToast)
  const [notificationToast, setNotificationToast] = useState([])
  const notificationAllData = useSelector((state) => state.user.notificationData.all)

  const [lenToast, setLenToast] = useState(0);
  const [isShowToast, setIsShowToast] = useState(false);

  const [countNotification, setCountNotification] = useState(0);

  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(({
      type: "SET_GROUP",
      payload: {}
    }))
    dispatch(({
      type: "SET_CHARTDATA"
    }))
    localStorageUtil.clearAppStorage();
    history.push("/login")
  }

  //notifications
  const executeNotifications = () => {
    if (notificationToast && notificationToast.length > 0) {
      setIsShowToast(true)
      openNotification(notificationToast[notificationToast.length - 1])
    } else {
      setIsShowToast(false)
    }
  }

  useEffect(() => {
    setNotificationToast(notificationToastReducer)
  }, [notificationToastReducer]);
  

  useEffect(() => {
    if (isShowToast === false) {
      executeNotifications();
    }
  }, [isShowToast]);

  useEffect(() => {

    //count Notifiaction not read
    if (notificationAllData && notificationAllData.length > 0) {
      const notReadNotification = notificationAllData.filter((item) => {
        if (item.notification_receipts) {
          if (item.notification_receipts.length === 0) {
            return true;
          } else if (item.notification_receipts[0].is_read === false) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
      setCountNotification(notReadNotification.length);
    } else {
      setCountNotification(0);
    }
  }, [notificationAllData]);

  const openNotification = async (toast) => {
    if (toast) {

      await setIsShowToast(true)
      let config = {
        key: toast.uuid,
        message: 'TOAST NOTIFICATION',
        description: toast.message,
        duration: 5,
        placement: "topLeft",
        onClose: () => { setIsShowToast(false) },
        className: "toast-notification-show"
      };


      if (toast.requires_confirmation) {
        config = {
          ...config,
          btn: (
            <Button type="primary" size="small" onClick={() => {
              Notification.close(toast.uuid)
              updatedToastNotification(toast.uuid, { is_confirmed: true })
            }}>
              Confirm
            </Button>
          )
        };
      }


      switch (toast.message_type) {
        case 2: //error
          await Notification.error(config);
          break;
        case 3: //warning
          await Notification.warning(config);
          break;
        case 4: //alarm
          await Notification.success({
            ...config,
            "icon": <Icon type="bell" />
          });
          break;
        default:
          await Notification.info(config);
          break;
      }

      updateToastNotification(toast.uuid);

      await dispatch(({
        type: "REMOVE_TOAST_NOTIFICATION",
        payload: { uuid: toast.uuid }
      }))
    }
  };

  useEffect(() => {
    if (lenToast < notificationToast.length) {
      executeNotifications();
    }
    setLenToast(notificationToast.length)
  }, [notificationToast]);

  useEffect(() => {
    obtainUserNotification();
  }, []);

  const existModuleNotification = () => {
    let isNotification = false
    if (modules) {

      modules.forEach(element => {
        if(element.name==="notification" || element.name==="Notification"){
          isNotification = true
        }
      });
    }
    return isNotification
  }


  const obtainUserNotification = async () => {

    const datavalue = await getUserNotification("all")
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
      dispatch(({
        type: "SET_USER_NOTIFICATION",
        payload: { "transport": "all", "notification": datavalue.data.notification }
      }))
      //await _setWarmWhiteLevel(datavalue.data);
    }
    //_setGLoading(false);
  }

  const updatedToastNotification = async (notification_uuid, form) => {
    console.log("entro UpdatedToast")
    const datavalue = await updatedToastNotificationShow(notification_uuid, form)
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {

      dispatch(({
        type: "SET_NOTIFICATION_RECEIPT",
        payload: { "notification_uuid": notification_uuid, "notification_receipt": datavalue.data.notification_receipt }
      }))

      //await _setWarmWhiteLevel(datavalue.data);
    }
    //_setGLoading(false);
  }


  useEffect(() => {
    const interval = setInterval(_ => {
      obtainNotification();

      if (isShowToast) {

      } else {
        executeNotifications();
      }
    }, 60000);
    return _ => clearInterval(interval);
  }, []);


  const obtainNotification = async () => {
    const datavalue = await getNotificationLastMinutes(3)
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {

      const toast_notification = datavalue.data.notification.filter((item) => {
        return (item.transport === 1)
      })

      dispatch(({
        type: "SET_TOAST_NOTIFICATION",
        payload: { "notification": toast_notification }
      }))

      dispatch(({
        type: "ADD_NEW_NOTIFICATIONS",
        payload: { "notification": datavalue.data.notification }
      }))
    }
  }

  const updateToastNotification = async (notification_uuid) => {
    console.log("ENTRO updateToastNotification")
    const datavalue = await updatedToastNotificationShow(notification_uuid, { is_show: true })
    if (datavalue.error) {
      if (datavalue.autherror) {
        message.info(datavalue.message);
        logout();
      } else {
        message.error(datavalue.message);
      }
    } else {
    }
  }

  return (
    <>
      {existModuleNotification() && <>
        <Badge count={countNotification} className={className}>

          <Icon
            type="user"
            className={className}
            onClick={() => {
              setIsModalVisible(true)
            }} />
        </Badge>
        <Modal
          title={
            <span>
              Notifications
                </span>
          }
          width="100%"
          maskClosable={true}
          style={{ top: "2em" }}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >

          {
            <UserNotification
              visible={isModalVisible}
            />
          }
        </Modal>
      </>}
    </>
  );
};

export default withRouter(NotificationIcon);
