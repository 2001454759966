

export const getMessageType = message_type => {
    let messageType = "";
    switch (message_type) {
        case 1:
            messageType = "Information";
            break;
        case 2:
            messageType = "Error";
            break;
        case 3:
            messageType = "Warning";
            break;
        case 4:
            messageType = "Alarm";
            break;
        default:
            messageType = "";
            break;
    }
    return messageType;
  }
  
  export const getMessageIconType = message_type => {
    let messageType = "";
    switch (message_type) {
        case 1:
            messageType = "info";
            break;
        case 2:
            messageType = "error";
            break;
        case 3:
            messageType = "warning";
            break;
        case 4:
            messageType = "success";
            break;
        default:
            messageType = "";
            break;
    }
    return messageType;
  }
  
  export const getNotificationType = notification_type => {
    let notificationType = "";
    switch (notification_type) {
        case 1:
            notificationType = "DIRECT";
            break;
        case 2:
            notificationType = "CONTACT";
            break;
        case 3:
            notificationType = "BROADCAST";
            break;
        case 4:
            notificationType = "CONTACT LANDLORD";
            break;
        case 5:
            notificationType = "CONTACT LANDLORD";
            break;
        case 6:
            notificationType = "CONTACT TECHINCAL";
            break;
        default:
            notificationType = "";
            break;
    }
    return notificationType;
  }
  
  export const getNotificationState = (notification_state) => {
    let notificationState = ""
    switch (notification_state) {
      case 1:
        notificationState = "READ"
        break;
      case 2:
        notificationState = "UNREAD"
        break;
      default:
        notificationState = "UNREAD"
        break;
    }
    return notificationState;
  }
  
  export const getIndexTransport = (transport_str) => {
    if (transport_str === "toast") {
      return 1;
    } else if (transport_str === "mail") {
      return 2;
    } else if (transport_str === "sms") {
      return 3;
    }
    return 1;
  }
  
  export const getElementType = group_type => {
    let elementType = "";
    switch (group_type) {
      case "Logical":
        elementType = "Logical Group";
        break;
      case "Control":
        elementType = "Control Group";
        break;
      case "Mix":
        elementType = "Mix Group";
        break;
      default:
        elementType = group_type;
        break;
    }
    return elementType;
  }
  
  export const getElementTypeForAPI = group_type => {
    let elementType = "";
    switch (group_type) {
      case "Logical":
        elementType = "group";
        break;
      case "Control":
        elementType = "group";
        break;
      case "Mix":
        elementType = "group";
        break;
      case "Building":
        elementType = "building";
        break;
      case "Company":
        elementType = "company";
        break;
      default:
        elementType = group_type;
        break;
    }
    return elementType;
  }